import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { SearchableCustomer, SearchCustomerLastKey, SearchCustomerReq } from "../utils/types";
import { CustomerStatus } from "../utils/types/customer";
import { reset } from "./auth-slice";

const customersAdapter = createEntityAdapter<SearchableCustomer>();

interface AdditionalSliceState {
    lastKeys?: SearchCustomerLastKey;
    query: string;
    status?: number;
    loading: boolean;
    loadingAdditional: boolean;
    error?: string;
}

interface CustomerSearchPayload extends SearchCustomerReq {
    update: boolean;
}

export const searchCustomers = createAsyncThunk(
    "customers/searchCustomers",
    async ({ update, ...payload }: CustomerSearchPayload) => {
        const res = await ReachCsAPI.searchCustomers(payload);
        return res;
    }
);

const additionalInitialState: AdditionalSliceState = {
    loading: true,
    loadingAdditional: false,
    query: "",
};

export const customersSlice = createSlice({
    name: "customers",
    initialState: customersAdapter.getInitialState(additionalInitialState),
    reducers: {
        customersInserted: customersAdapter.setAll,
        customersUpdated: customersAdapter.upsertMany,
        queryUpdated(state, action: PayloadAction<string>) {
            state.query = action.payload;
            state.lastKeys = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchCustomers.fulfilled, (state, action) => {
                if (action.meta.arg.update) {
                    customersAdapter.upsertMany(state, action.payload.results);
                } else {
                    customersAdapter.setAll(state, action.payload.results);
                }
                state.lastKeys = action.payload.lastKeys;
                state.loading = false;
                state.loadingAdditional = false;
                state.error = undefined;
            })
            .addCase(searchCustomers.pending, (state, action) => {
                if (action.meta.arg.update) {
                    state.loadingAdditional = true;
                } else {
                    state.loading = true;
                }
                state.error = undefined;
            })
            .addCase(searchCustomers.rejected, (state, action) => {
                state.loading = false;
                state.loadingAdditional = false;
                state.error = "Error";
            })
            .addCase(reset, () => customersAdapter.getInitialState(additionalInitialState));
    },
});

export const { customersUpdated, customersInserted, queryUpdated } = customersSlice.actions;

export const customersSelector = customersAdapter.getSelectors((state: RootState) => state.customers);

export const customerSearchSelector = createSelector(
    (state: RootState) => state.customers,
    (state) => ({
        loading: state.loading,
        lastKeys: state.lastKeys,
        loadingAdditional: state.loadingAdditional,
        query: state.query,
    })
);
