import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getBytes } from "../utils/data-conversion";
import { ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { ReachPlan } from "../utils/types";

const plansAdapter = createEntityAdapter<ReachPlan>({
    selectId: (plan) => plan.name,
    sortComparer: (a, b) => getBytes(a.planData, a.dataUnit) - getBytes(b.planData, b.dataUnit),
});

export const fetchReachPlans = createAsyncThunk("plans/fetchReachPlans", async () => {
    const res = await ReachCsAPI.getActivePlans();
    return res;
});

export const plansSlice = createSlice({
    name: "plans",
    initialState: plansAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchReachPlans.fulfilled, (state, action) => {
            plansAdapter.setAll(state, action.payload);
        });
    },
});

export const plansSelector = plansAdapter.getSelectors((state: RootState) => state.plans);
