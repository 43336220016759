import React, { PropsWithChildren, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setSnackbarMessage, snackbarSelector } from "../features/snackbar-slice";
import { useAppDispatch } from "../utils/store";

export function SnackbarProvider({ children }: PropsWithChildren<unknown>) {
    const message = useSelector(snackbarSelector);

    return (
        <>
            {message && (
                <div
                    className="sticky-top alert text-white alert-dismissible text-center"
                    style={{ borderRadius: 0, backgroundColor: "black" }}
                    role="alert">
                    {message}
                </div>
            )}
            {children}
        </>
    );
}

export enum Duration {
    INDEFINITE,
    SHORT = 5000,
    LONG = 10000,
}

export const useSnackbar = () => {
    const dispatch = useAppDispatch();
    const message = useSelector(snackbarSelector);

    const [duration, setDuration] = useState(Duration.SHORT);

    useEffect(() => {
        if (message && message.length > 0 && duration !== Duration.INDEFINITE) {
            setTimeout(() => {
                dispatch(setSnackbarMessage(undefined));
            }, duration);
        }
    }, [message]);

    return (message?: string, duration = Duration.SHORT) => {
        setDuration(duration);
        dispatch(setSnackbarMessage(message));
    };
};
