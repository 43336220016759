import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { AutoDeactivationStatusReq, CreditPurchaseReq, IntlCountry, IRTrip } from "../utils/types";
import { reset } from "./auth-slice";

interface IRPurchaseSliceState {
    travelers: string[];
    destinations: IntlCountry[];
    tentativeStartDate?: number;
    dataCap: Record<string, number>;
}

const initialState: IRPurchaseSliceState = {
    travelers: [],
    destinations: [],
    dataCap: {},
};

export const estimateTripCredits = createReachApiThunk(
    "irPurchase/estimateTripCredits",
    ({ countries, customerId }: { countries: string[]; customerId: string }) =>
        ReachCsAPI.estimateTripCredits(countries, customerId)
);

export const cancelTrip = createReachApiThunk(
    "irPurchase/cancelTrip",
    ({ tripId, customerId, userCancelReason }: { tripId: string; customerId: string; userCancelReason: string }) =>
        ReachCsAPI.cancelTrip(tripId, customerId, userCancelReason)
);

export const purchaseCredit = createReachApiThunk("irPurchase/purchaseCredit", (payload: CreditPurchaseReq) =>
    ReachCsAPI.purchaseCredit(payload)
);

export const createIntlTrips = createReachApiThunk("irPurchase/createIntlTrips", (payload: IRTrip[]) =>
    ReachCsAPI.createIntlTrips(payload)
);

export const activateTrip = createReachApiThunk("irPurchase/activateTrip", (tripId: string) =>
    ReachCsAPI.activateTrip(tripId)
);

export const troubleshootIR = createReachApiThunk("irPurchase/troubleshootIR", (customerId: string) =>
    ReachCsAPI.troubleshootIR(customerId)
);

export const getTroubleshootIRStatus = createReachApiThunk("irPurchase/getTroubleshootIRStatus", (customerId: string) =>
    ReachCsAPI.getTroubleshootIRStatus(customerId)
);

export const updateAutoDeactivationStatus = createReachApiThunk(
    "irPurchase/updateAutoDeactivationStatus",
    ({ tripId, payload }: { tripId: string; payload: AutoDeactivationStatusReq }) =>
        ReachCsAPI.updateAutoDeactivationStatus(tripId, payload)
);

export const irPurchaseSlice = createSlice({
    name: "irPurchase",
    initialState,
    reducers: {
        irPurchaseReset: () => initialState,
        travelersUpdated(state, action: PayloadAction<string[]>) {
            state.travelers = action.payload;
        },
        irCountryAdded(state, action: PayloadAction<IntlCountry>) {
            if (!state.destinations.find((country) => country.countryCode === action.payload.countryCode)) {
                state.destinations.push(action.payload);
            }
        },
        irCountryRemoved(state, action: PayloadAction<string>) {
            state.destinations = state.destinations.filter((item) => item.countryCode !== action.payload);
        },
        tentativeDateUpdated(state, action: PayloadAction<number>) {
            state.tentativeStartDate = action.payload;
        },
        dataCapUpdated(state, action: PayloadAction<Record<string, number>>) {
            state.dataCap = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(reset, () => initialState);
    },
});

export const selectIrPurchase = (state: RootState) => state.irPurchase;

export const {
    travelersUpdated,
    irPurchaseReset,
    irCountryAdded,
    irCountryRemoved,
    tentativeDateUpdated,
    dataCapUpdated,
} = irPurchaseSlice.actions;
