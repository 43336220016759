import React, { DetailedHTMLProps } from "react";
import classNames from "classnames";

export interface CardProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    disabled?: boolean;
}

export const Card = (props: CardProps) => {
    const { disabled = false, onClick, className, ...rest } = props;
    return (
        <div
            className={classNames("reach-card", { disabled }, className)}
            onClick={!disabled ? onClick : undefined}
            {...rest}
        />
    );
};
