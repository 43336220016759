import React, { PropsWithChildren, useEffect, useState } from "react";
import { selectUserInfo } from "../features/user-role-slice";
import { useAppSelector } from "../utils/store";

declare global {
    interface Window {
        $zoho: {
            salesiq: {
                floatbutton?: {
                    visible(param: "show" | "hide"): void;
                };
                visitor?: {
                    email(value: string): void;
                    name(value: string): void;
                    info(value: Record<string, string | undefined>): void;
                };
                reset?(): void;
                ready(): void;
            };
        };
    }
}

export function ZohoChatProvider({ children }: PropsWithChildren<unknown>) {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const userInfo = useAppSelector(selectUserInfo);

    useEffect(() => {
        window.$zoho = window.$zoho || {};
        window.$zoho.salesiq = window.$zoho.salesiq || {
            widgetcode: process.env.ZOHO_CHAT_WIDGETCODE,
            values: {},
            ready: function () {},
        };
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "zsiqscript";
        script.defer = true;
        script.src = "https://salesiq.zoho.com/widget";
        const tag = document.getElementsByTagName("script")[0];
        tag.parentNode?.insertBefore(script, tag);
        window.$zoho.salesiq.ready = function () {
            setScriptLoaded(true);
            window.$zoho.salesiq.floatbutton?.visible("hide");
        };
    }, []);

    useEffect(() => {
        if (!scriptLoaded) {
            return;
        }
        if (userInfo.emailId) {
            window.$zoho.salesiq.floatbutton?.visible("show");
            window.$zoho.salesiq.visitor?.email(userInfo.emailId);
            if (userInfo.firstName && userInfo.lastName) {
                window.$zoho.salesiq.visitor?.name(`${userInfo.firstName} ${userInfo.lastName}`);
            }
            if (userInfo.accountId && userInfo.supportDepartmentId) {
                window.$zoho.salesiq.visitor?.info({
                    accountId: userInfo.accountId,
                    departmentId: userInfo.supportDepartmentId,
                });
            }
        } else {
            window.$zoho.salesiq.floatbutton?.visible("hide");
        }
    }, [userInfo, scriptLoaded]);

    return <>{children}</>;
}

export function setZohoVisitorInfo(info: Record<string, string | undefined>) {
    window.$zoho.salesiq.visitor?.info(info);
}
