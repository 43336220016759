import axios from "axios";
import { setupRequestInterceptor } from "./api-client";
import {
    GenericRes,
    ReachPlan,
    SearchCustomerReq,
    SearchCustomerRes,
    ImeiRes,
    TaxType,
    TaxRes,
    SimCostRes,
    MNPValidateRes,
    CustomerProfile,
    Coupon,
    IccIdRes,
    ActivationReq,
    CardHoldRes,
    UpdatePortReq,
    Activation,
    ChangePlanReq,
    AutoTopupReq,
    CDRDataUsageRes,
    TopupPulseRes,
    ManageFeatureReq,
    ManageFeatureRes,
    TopupReq,
    TopupHistoryRes,
    CustomizedBill,
    ReferralAnalytics,
    ZipcodeRes,
    DiscountRes,
    AccountSwapReq,
    AddCreditReq,
    CDRReportReq,
    CreditPurchaseRes,
    IldStatusRes,
    ILDUsageRes,
    CreditPurchaseReq,
    InternationalCountriesRes,
    CDRReportRes,
    HubspotEngagementNotesReq,
    OrderReq,
    MaxInternationalCountriesRes,
    LeaveReachReq,
    ReconnectionReq,
    UpdatePortoutReq,
    EstimatedBill,
    OpsEventsPayload,
    TenantAccount,
    QRCCategoriesRes,
    PermissionsRes,
    SearchUserReq,
    SearchableUser,
    SearchUserRes,
    CreateUserReq,
    CancelTopupRes,
    RefundTopupRes,
    ImeiInfoRes,
    AddonChargeReq,
    PortoutInfo,
    ShipmentOrder,
    NewToPortReq,
    IRTrip,
    EstimateTripRes,
    IRDataUsageRes,
    PayInAdvanceReq,
    PlanHistoryItem,
    InstantChargeReq,
    MDNAlertsRes,
    PromiseToPayHistoryRes,
    AutoDeactivationStatusReq,
    AccountHistoryRes,
    TicketReq,
    TicketPayload,
    TicketRes,
    TicketCommentReq,
    TicketAttachment,
    PaymentPageReq,
    PaymentPageRes,
    TicketComment,
} from "./types";
import { Address, Customer, CustomerStatus, ShippingMethod } from "../utils/types/customer";
import { AsyncThunkPayloadCreatorReturnValue, createAsyncThunk } from "@reduxjs/toolkit";
import { Bill } from "./types/bill";
import { RolesReq, RolesRes, UserRolesRes } from "./types/user-roles";
import { AlertActivityType } from "../screens/alerts-history-page";

export const reachEndpoint = process.env.API_BASE_URL! + process.env.API_VER!;

export class ReachCsAPI {
    static client = axios.create({
        baseURL: reachEndpoint,
        timeout: 30000,
    });

    static async getRolesByEmailId(emailId: string) {
        const res = await this.client.get<GenericRes<UserRolesRes>>("/user/role", { params: { emailId } });
        return res.data.data;
    }

    static async searchCustomers(payload: SearchCustomerReq) {
        const res = await this.client.post<GenericRes<SearchCustomerRes>>("/customer/search", payload);
        return res.data.data;
    }

    static async createAccount(payload: Partial<Customer>, reachMvne: string) {
        const res = await this.client.post<GenericRes<CustomerProfile>>("/account/create", payload, {
            headers: {
                "x-reach-mvne": reachMvne,
            },
        });
        return res.data.data;
    }

    static async updateAccount(id: string, payload: Partial<Customer>, reachMvne: string) {
        const res = await this.client.put<GenericRes<Partial<Customer>>>(`/account/update/fields/${id}`, payload, {
            headers: {
                "x-reach-mvne": reachMvne,
            },
        });
        return res.data.data;
    }

    static async getActivePlans() {
        const res = await this.client.get<GenericRes<ReachPlan[]>>("/reachplans/active");
        return res.data.data;
    }

    static async checkIMEI(imei: string, reachMvne: string, countryCode = "USA") {
        const headers: any = {};
        if (reachMvne) {
            headers["x-reach-mvne"] = reachMvne;
        }
        const res = await this.client.get<GenericRes<ImeiRes>>(`/imei/${countryCode}/${imei}`, {
            headers,
        });
        return res.data.data;
    }

    static async checkIMEIInfo(imei: string, reachMvne: string) {
        const headers: any = {};
        if (reachMvne) {
            headers["x-reach-mvne"] = reachMvne;
        }
        const res = await this.client.get<GenericRes<ImeiInfoRes>>(`/imei/info/${imei}`, {
            headers,
        });
        return res.data.data;
    }

    static async getTaxByAddress(
        amount: string,
        type: TaxType,
        address: Address,
        additionalLines: number,
        countryCode = "USA"
    ) {
        const res = await this.client.post<GenericRes<TaxRes>>(`/taxes/${countryCode}`, {
            amount,
            address,
            type,
            additionalLines,
        });
        return res.data.data;
    }

    static async getTaxByCustomer(payload: {
        amount: string;
        type: TaxType;
        customerId: string;
        additionalLines: number;
    }) {
        const res = await this.client.post<GenericRes<TaxRes>>("/taxes/USA", payload);
        return res.data.data;
    }

    static async getSIMCost(countryCode = "USA") {
        const res = await this.client.get<GenericRes<SimCostRes>>(`/sim/countryCode/${countryCode}`);
        return res.data.data;
    }

    static async submitMNPValidate(phoneNumber: string, isPrimary: boolean, primaryMvne: string, countryCode = "USA") {
        const headers: any = {};
        if (isPrimary) {
            headers["x-is-primary"] = "Yes";
        }
        if (primaryMvne && !isPrimary) {
            headers["x-is-primary"] = "No";
            headers["x-primary-mvne"] = primaryMvne;
        }
        const res = await this.client.post<GenericRes<string>>(`/port/validate/${countryCode}/${phoneNumber}`, null, {
            headers,
        });
        return res.data.data;
    }

    static async validateMNP(refNumber: string) {
        const res = await this.client.get<GenericRes<MNPValidateRes>>(`/port/validate/result/${refNumber}`);
        return { res: res.data.data, reachMvne: res.headers["x-reach-mvne"] };
    }

    static async fetchProfile(id: string) {
        const res = await this.client.get<GenericRes<CustomerProfile>>(`/account/profile/${id}`);
        return res.data.data;
    }

    static async getCoupon(couponCode: string) {
        const res = await this.client.get<GenericRes<Coupon>>(`/coupon/${couponCode}`);
        return res.data.data;
    }

    static async setAutopayCard(cardId: string) {
        const res = await this.client.put(`/customer/cc/autopay/${cardId}`, {});
        return res.data.data;
    }

    static async removeCard(cardId: string) {
        const res = await this.client.delete(`/customer/cc/delete/${cardId}`);
        return res.data.data;
    }

    static async reservePromo(customerId: string, couponId: string) {
        const res = await this.client.post("/coupon/reserve/promo", {
            customerId,
            couponId,
        });
        return res.data.data;
    }

    static async applyCoupon(customerId: string, couponId: string) {
        const res = await this.client.post("/coupon/apply/customer", {
            customerId,
            couponId,
        });
        return res.data.data;
    }

    static async getCouponEligibility(emailId: string) {
        const res = await this.client.get<GenericRes<Coupon>>(
            `/coupon/eligibility?emailId=${encodeURIComponent(emailId)}`
        );

        return res.data.data;
    }

    static async validateIccId(customerId: string, iccId: string) {
        const res = await this.client.get<GenericRes<IccIdRes>>(`/icc/validate/${customerId}/${iccId}`);
        return res.data.data;
    }

    static async validateIccIdByMvne(iccId: string, mvne: string) {
        const res = await this.client.post<GenericRes<IccIdRes>>(`/iccid/validate`, {
            iccId,
            mvne,
        });
        return res.data.data;
    }

    static async activateLine(payload: ActivationReq) {
        const res = await this.client.post<GenericRes<any>>("/activation", payload);
        return res.data.data;
    }

    static async updatePort(customerId: string, payload: UpdatePortReq) {
        const res = await this.client.put<GenericRes<any>>(`/update/portin/${customerId}`, payload);
        return res.data.data;
    }

    static async createCardHold(customerId: string) {
        const res = await this.client.post<GenericRes<CardHoldRes>>(`/customer/cc/hold/${customerId}`);
        return res.data.data;
    }

    static async getCustomerByEmail(email: string) {
        const res = await this.client.get<GenericRes<Customer>>(`/customers/username?id=${encodeURIComponent(email)}`);
        return res.data.data;
    }

    static async createCustomer(customer: Customer, reachMvne: string, countryCode = "USA") {
        const headers: any = {};
        if (reachMvne) {
            headers["x-reach-mvne"] = reachMvne;
        }
        const res = await this.client.post<GenericRes<Customer>>(`/${countryCode}/customers`, customer, {
            headers,
        });
        return res.data.data;
    }

    static async sendPaymentLink(customerId: string, resetPassword = false) {
        const res = await this.client.put<GenericRes<CustomerProfile>>(
            `/account/generate/purchase/link/${customerId}`,
            {},
            {
                params: {
                    resetPassword,
                },
            }
        );
        return res.data.data;
    }

    static async addCreditCard(customerId: string, resetPassword = false) {
        const res = await this.client.put<GenericRes<CustomerProfile>>(
            `/account/generate/addcard/link/${customerId}`,
            {},
            {
                params: {
                    resetPassword,
                },
            }
        );
        return res.data.data;
    }

    static async updateCardExpiry(ccid: string, expiry: string) {
        const res = await this.client.put(`/cc/update/${ccid}/expiry/${expiry}`);
        return res.data.data;
    }

    static async updateHubspot(payload: { email: string } & Record<string, string | undefined>) {
        const res = await this.client.put(`hubspot/customers/save`, payload);
        return res.data.data;
    }

    static async trackIR(orderID: string, fname: string, email: string, couponCode: string, campaignid: string) {
        const res = await axios.get("https://www.ref-r.com/campaign/t1/settings", {
            params: {
                bid_e: "A528E96A9DD82CBC83DE6AAA8F31B801",
                bid: "26304",
                t: "420",
                event: "register",
                email,
                orderID,
                campaignid,
                fname,
                couponCode,
            },
        });

        console.log(res.data);
    }

    static async cancelOrder(customerId: string, orderIds: string[], reason: string, reimburse: boolean) {
        const res = await this.client.put<GenericRes<string>>(`/shipment/order/cancel/${customerId}`, {
            orderIds,
            reason,
            reimburse,
        });
        return res.data.data;
    }

    static async getActivation(customerId: string) {
        const res = await this.client.get<GenericRes<Activation>>(`/activation/status/${customerId}`);
        return res.data.data;
    }

    static async changePlan(payload: ChangePlanReq) {
        const res = await this.client.post<GenericRes<any>>("/reachplan/change", payload);
        return res.data.data;
    }

    static async updateAutoTopup(payload: AutoTopupReq) {
        const res = await this.client.post("/auto/topup/update", payload);
        return res.data.data;
    }

    static async getProvConfig(keys: string) {
        const res = await this.client.get<GenericRes<Record<string, string>>>(`/config/id/PROV_CONFIG/keys/${keys}`);
        return res.data.data;
    }

    static async getCDRDataUsage(customerId: string, startDate?: string, endDate?: string) {
        const res = await this.client.get<GenericRes<CDRDataUsageRes>>(`/cdr/data/topup/usage/customer/${customerId}`, {
            params: {
                startDate,
                endDate,
            },
        });

        return res.data.data;
    }

    static async getTopupPulse() {
        const res = await this.client.get<GenericRes<TopupPulseRes>>("/topup/pulse");
        return res.data.data;
    }

    static async cancelTopup(customerId: string) {
        const res = await this.client.put<GenericRes<CancelTopupRes>>(`/topup/cancel/customer/${customerId}`);
        return res.data.data;
    }

    static async refundTopup(customerId: string) {
        const res = await this.client.put<GenericRes<RefundTopupRes>>(`/topup/refund/customer/${customerId}`);
        return res.data.data;
    }

    static async manageFeature(customerId: string, payload: ManageFeatureReq) {
        const res = await this.client.put<GenericRes<ManageFeatureRes>>(
            `/customer/manage/feature/${customerId}`,
            payload
        );
        return res.data.data;
    }

    static async addTopup(customerId: string, { isChargable, ...payload }: TopupReq) {
        const res = await this.client.post(`/topup/customer/${customerId}`, payload, {
            params: {
                chargable: isChargable,
            },
        });
        return res.data.data;
    }

    static async setSimSwap(customerId: string, simId: string) {
        const res = await this.client.put(`/customer/sim/swap/${customerId}/${simId}`, {});
        return res.data.data;
    }

    static async getTopupHistory(groupId: string) {
        const res = await this.client.get<GenericRes<TopupHistoryRes>>(`/topup/billing/group/${groupId}`);
        return res.data.data;
    }

    static async resetNetwork(customerId: string) {
        const res = await this.client.post(`/customer/reset/${customerId}`, {});
        return res.data.data;
    }

    static async getNetworkResetStatus(customerId: string) {
        const res = await this.client.get(`/customer/reset/status/${customerId}`, {});
        return res.data.data;
    }

    static async getBillingHistory(customerId: string, startDate?: string, endDate?: string) {
        const res = await this.client.get<GenericRes<Bill[]>>(`/billing/customer/${customerId}`, {
            params: {
                startDate,
                endDate,
            },
        });
        return res.data.data;
    }

    static async getCustomizedBills(billId: number) {
        const res = await this.client.get<GenericRes<CustomizedBill>>(`/billing/customize/${billId}`);

        return res.data.data;
    }

    static async resendBill(billId: number, currentBill: boolean) {
        const res = await this.client.post(
            `/billing/resend/invoice/bill/${billId}`,
            {},
            {
                params: {
                    currentBill,
                },
            }
        );

        return res.data.data;
    }

    static async payBill(customerId: string, ccUUID: string, billId: number) {
        const res = await this.client.post("/payment/customer", {
            customerId,
            ccUUID,
            billId,
        });
        return res.data.data;
    }

    static async regenerateBill(customerId: string) {
        const res = await this.client.post(`/billing/regenerate/customer/${customerId}`, {});
        return res.data.data;
    }

    static async waiveOffLatePayment(customerId: string) {
        const res = await this.client.put(`/billing/waiveoff/latepayment/customer/${customerId}`, {});
        return res.data.data;
    }

    static async getGroupReferralCredits(groupId: string) {
        const res = await this.client.get<GenericRes<ReferralAnalytics>>(`/coupon/referral/analytics/group/${groupId}`);

        return res.data.data;
    }

    static async checkZipcode(zipcode: string, countryCode = "USA") {
        const res = await this.client.get<GenericRes<ZipcodeRes>>(`/zip/${countryCode}/${zipcode}`);

        return res.data.data;
    }

    static async manageCDMAless(customerId: string, enable: boolean) {
        const res = await this.client.put(
            `/customer/manage/cdmaless/${customerId}`,
            {},
            {
                params: {
                    enable,
                },
            }
        );
        return res.data.data;
    }

    static async getDiscountAnalytics(customerId: string) {
        const res = await this.client.get<GenericRes<DiscountRes>>(`/analytics/discount/customer/${customerId}`);
        return res.data.data;
    }

    static async swapAccount(payload: AccountSwapReq) {
        const res = await this.client.put("/customers/swap", payload);
        return res.data.data;
    }

    static async generateNewMDN(customerId: string, zipCode: string, chargable: boolean) {
        const res = await this.client.put(
            `/customer/mdn/swap/${customerId}`,
            {},
            {
                params: {
                    zipCode,
                    chargable,
                },
            }
        );
        return res.data.data;
    }

    static async getMDNSwapStatus(customerId: string) {
        const res = await this.client.get(`/customer/mdn/swap/status/${customerId}`);
        return res.data.data;
    }

    static async addCredit(payload: AddCreditReq) {
        const res = await this.client.post("/credit/ids", payload);
        return res.data.data;
    }

    static async generateCDRReport(customerId: string, payload: CDRReportReq) {
        const res = await this.client.post(`/report/cdr/records/customers/${customerId}`, payload);
        return res.data.data;
    }

    static async getIldCreditStatus(groupId: string) {
        const res = await this.client.get<GenericRes<CreditPurchaseRes>>(`/ild/status/group/${groupId}`);
        return res.data.data;
    }

    static async deactivateIld(primaryId: string, customerId: string, adjustCreditInBill: boolean) {
        const res = await this.client.post<GenericRes<IldStatusRes>>("/ild/deactivate", {
            primaryId,
            customerId,
            adjustCreditInBill,
        });
        return res.data.data;
    }

    static async getIldOverallUsage(groupId: string) {
        const res = await this.client.get<GenericRes<ILDUsageRes>>(`/ild/usage/${groupId}`);
        return res.data.data;
    }

    static async purchaseCredit(ildPurchaseReq: CreditPurchaseReq) {
        const res = await this.client.post<GenericRes<CreditPurchaseRes>>("/customer/purchase/credit", ildPurchaseReq);
        return res.data.data;
    }

    static async activateIld(primaryId: string, customerId: string) {
        const res = await this.client.post<GenericRes<IldStatusRes>>("/ild/activate", {
            primaryId,
            customerId,
        });
        return res.data.data;
    }

    static async getInternationalCountryList(mvne: string) {
        const res = await this.client.get<GenericRes<InternationalCountriesRes>>(
            `/intl/roaming/countries/mvne/${mvne}`
        );
        return res.data.data;
    }

    static async getInternationalRoamingCountries() {
        const res = await this.client.get<GenericRes<MaxInternationalCountriesRes>>("/intl/roaming/countries");
        return res.data.data;
    }

    static async getCDRReports(customerId: string) {
        const res = await this.client.get<GenericRes<CDRReportRes[]>>(`/report/cdr/customers/${customerId}`);
        return res.data.data;
    }

    static async sendEngagementNotes(payload: HubspotEngagementNotesReq) {
        const res = await this.client.post<GenericRes<{ status: string }>>("/hubspot/engagement/note/send", payload);
        return res.data.data;
    }

    static async createOrder(order: OrderReq) {
        const res = await this.client.post("/shipment/order/", order);
        return res.data.data;
    }

    static async leaveReach(customerId: string, payload: LeaveReachReq) {
        const res = await this.client.put(`/leaving/reach/customer/${customerId}`, payload);
        return res.data.data;
    }

    static async updateLeaveReach(customerId: string, payload: LeaveReachReq) {
        const res = await this.client.put(`/leaving/reach/update/customer/${customerId}`, payload);
        return res.data.data;
    }

    static async reconnect(payload: ReconnectionReq) {
        const res = await this.client.put("/leaving/reach/reconnect", payload);
        return res.data.data;
    }

    static async getPortoutInfo(customerId: string) {
        const res = await this.client.get<GenericRes<PortoutInfo>>(`/customer/portout/status/${customerId}`);
        return res.data.data;
    }

    static async updatePortout(customerId: string, payload: UpdatePortoutReq) {
        const res = await this.client.put(`/customer/update/portout/${customerId}`, payload);
        return res.data.data;
    }

    static async getBillingForecast(customerId: string, endDate?: string) {
        const res = await this.client.get<GenericRes<EstimatedBill>>(`/billing/customize/forecast/${customerId}`, {
            params: {
                endDate,
            },
        });
        return res.data.data;
    }

    static async addOpsEvents(payload: OpsEventsPayload, accountSrc: string) {
        const res = await this.client.put(`/ops/events/add`, payload, {
            headers: {
                "x-account-src": accountSrc,
            },
        });
        return res.data.data;
    }

    static async getTenantId(accountId: string) {
        const res = await this.client.get<GenericRes<TenantAccount>>(`/operation/account/${accountId}`);
        return res.data.data;
    }

    static async getQRCCategories() {
        const res = await this.client.get<GenericRes<QRCCategoriesRes>>("/qrc/categories");
        return res.data.data;
    }

    static async getAllPermissions() {
        const res = await this.client.get<GenericRes<PermissionsRes>>("/permissions");
        return res.data.data;
    }

    static async searchUsers(payload: SearchUserReq) {
        const res = await this.client.post<GenericRes<SearchUserRes>>("/user/search", payload);
        return res.data.data;
    }

    static async createUser(payload: CreateUserReq) {
        const res = await this.client.post<GenericRes<UserRolesRes>>("/user", payload);
        return res.data.data;
    }

    static async updateUser(payload: CreateUserReq) {
        const res = await this.client.put<GenericRes<UserRolesRes>>("/user", payload, {
            params: { emailId: payload.emailId },
        });
        return res.data.data;
    }

    static async changeVoicemailPassword(customerId: string, password: string) {
        const res = await this.client.put(`/customer/reset/voicemail/password/${customerId}`, password);
        return res.data.data;
    }

    static async setCallerIdStatus(customerId: string, isBlocked: boolean) {
        const operation = isBlocked ? "add" : "remove";
        const res = await this.client.post(`/customer/cidb/${operation}/${customerId}`, {});
        return res.data.data;
    }

    static async addAddonCharge(payload: AddonChargeReq) {
        const res = await this.client.post(`/addon/charges`, payload);
        return res.data.data;
    }

    static async getOrderHistory(customerId: string) {
        const res = await this.client.get<GenericRes<ShipmentOrder[]>>(`/shipment/order/customer/${customerId}`);
        return res.data.data;
    }

    static async newToPortNumber(customerId: string, chargable: boolean, payload: NewToPortReq) {
        const res = await this.client.post(`/customer/new/to/port/${customerId}`, payload, {
            params: {
                chargable,
            },
        });
        return res.data.data;
    }

    static async getIntlTrips(groupId: string) {
        const res = await this.client.get<GenericRes<IRTrip[]>>(`/intl/roaming/trip/customer/group/${groupId}`);
        return res.data.data;
    }

    static async estimateTripCredits(countries: string[], customerId: string) {
        const res = await this.client.post<GenericRes<EstimateTripRes>>(
            `/intl/roaming/trip/estimated/credits/${customerId}`,
            countries
        );
        return res.data.data;
    }

    static async createIntlTrips(trips: IRTrip[]) {
        const res = await this.client.post<GenericRes<IRTrip[]>>("/intl/roaming/trip", trips);

        return res.data.data;
    }

    static async updateTrip(tripId: string, trip: IRTrip) {
        const res = await this.client.put<GenericRes<IRTrip>>(`/intl/roaming/trip/${tripId}`, trip);

        return res.data.data;
    }

    static async activateTrip(tripId: string) {
        const res = await this.client.put(`/intl/roaming/trip/activate/${tripId}`);
        return res.data.data;
    }

    static async getIntlOverallDataUsage(tripId: string) {
        const res = await this.client.get<GenericRes<IRDataUsageRes>>(`/ir/cdr/overall/monthly/usage/trip/${tripId}`);

        return res.data.data;
    }

    static async deactivateTrip(tripId: string) {
        const res = await this.client.put(`/intl/roaming/trip/deactivate/${tripId}`);
        return res.data.data;
    }

    static async getIntlAvailableCredits(customerId: string) {
        const res = await this.client.get(`/customer/available/credit/${customerId}?creditType=IR`);
        return res.data.data;
    }

    static async payInAdvance(payload: PayInAdvanceReq) {
        const res = await this.client.post("/instant/purchase/payinadvance", payload);
        return res.data.data;
    }

    static async addInstantCharge(payload: InstantChargeReq) {
        const res = await this.client.post("/instant/purchase", payload);
        return res.data.data;
    }

    static async getPlanHistory(customerId: string) {
        const res = await this.client.get<GenericRes<PlanHistoryItem[]>>(`/reachplan/history/plan/${customerId}`);
        return res.data.data;
    }

    static async cancelReachPlan(customerId: string) {
        const res = await this.client.post(`/reachplan/cancel/${customerId}`, {});
        return res.data.data;
    }

    static async troubleshootIR(customerId: string) {
        const res = await this.client.put(`/intl/roaming/troubleshoot/customer/${customerId}`, {});
        return res.data.data;
    }

    static async getTroubleshootIRStatus(customerId: string) {
        const res = await this.client.get(`/intl/roaming/troubleshoot/status/customer/${customerId}`);
        return res.data.data;
    }

    static async setPromiseToPayDate(customerId: string, date: string) {
        const res = await this.client.put(`/promisetopay/${customerId}`, {
            ptpDate: date,
        });
        return res.data.data;
    }

    static async getAlertsHistory(customerId: string, startDate: string, endDate: string) {
        const res = await this.client.get<GenericRes<MDNAlertsRes>>(`/operation/all/${customerId}`, {
            params: {
                startDate,
                endDate,
            },
        });
        return res.data.data;
    }

    static async getPromisetopayHistory(customerId: string, startDate: string, endDate: string) {
        const res = await this.client.get<GenericRes<PromiseToPayHistoryRes>>(
            `/promisetopay/history/plan/${customerId}`,
            {
                params: {
                    startDate,
                    endDate,
                },
            }
        );
        return res.data.data;
    }

    static async getAccountHistory(customerId: string, startDate: string, endDate: string) {
        const res = await this.client.get<GenericRes<AccountHistoryRes>>(`/customer/activity/${customerId}`, {
            params: {
                startDate,
                endDate,
            },
        });
        return res.data.data.filter((item) => item.success);
    }

    static async updateAutoDeactivationStatus(tripId: string, payload: AutoDeactivationStatusReq) {
        const res = await this.client.put(`/intl/roaming/update/skipInfo/trip/${tripId}`, payload);
        return res.data.data;
    }

    static async cancelTrip(tripId: string, customerId: string, userCancelReason: string) {
        const res = await this.client.put(
            `/intl/roaming/trip/cancel/${tripId}/customer/${customerId}`,
            {},
            {
                params: {
                    userCancelReason,
                },
            }
        );
        return res.data.data;
    }

    static async sendTemporaryPassword(emailId: string) {
        const res = await this.client.post(
            "/account/reset/password/send/mail",
            {},
            {
                params: {
                    emailId,
                },
            }
        );
        return res.data.data;
    }

    static async getAppData() {
        const res = await this.client.get<
            GenericRes<{
                operators: Operator[];
                shippingRates: ShippingMethod[];
                perSimCost: {
                    USA: SimCostRes;
                };
            }>
        >("/app/data");
        return res.data.data;
    }

    static async rectifyMDNAlert(
        customerId: string,
        payload: { operation: keyof typeof AlertActivityType; mode: "manual" | "automatic" }
    ) {
        const res = await this.client.post(`/operation/rectify/${customerId}`, payload);
        return res.data.data;
    }

    static async generatePaymentPage(payload: PaymentPageReq) {
        const res = await this.client.post<GenericRes<PaymentPageRes>>("/account/generate/page", payload);
        return res.data.data;
    }

    static async getAllTickets() {
        const res = await this.client.get("/zoho/tickets/getAll");
        return res.data.data;
    }

    static async getAllDepTickets(params: TicketPayload) {
        const res = await this.client.get("/zoho/tickets/getAll", {
            params: {
                departmentID: params.departmentID,
                limit: params.limit,
                createdTimeRange: params.createdTimeRange,
                subject: params.subject,
                status: params.status,
                from: params.from,
            },
        });
        return res.data.data;
    }

    static async getTicketById(ticketId: string) {
        const res = await this.client.get(`/zoho/tickets/get/${ticketId}`);
        return res.data.data;
    }

    static async createTicket(body: TicketReq) {
        const res = await this.client.post<GenericRes<TicketRes>>("/zoho/ticket/create", body);
        return res.data.data;
    }

    static async updateZohoTicket(ticketId: string, body: TicketReq) {
        const res = await this.client.put(`/zoho/ticket/update/${ticketId}`, body);
        return res.data.data;
    }

    static async getTicketsByCustomerId(customerId: string) {
        const res = await this.client.get<GenericRes<TicketRes[]>>(`/zoho/ticket/search/${customerId}`);
        return res.data.data;
    }

    static async createTicketComment(ticketId: string, body: TicketCommentReq) {
        const res = await this.client.post(`/zoho/ticket/comment/${ticketId}`, body);
        return res.data.data;
    }

    static async getTicketComments(ticketId: string) {
        const res = await this.client.get<GenericRes<TicketComment[]>>(`/zoho/ticket/comment/${ticketId}`);
        return res.data.data;
    }

    static async updateTicketComment(ticketId: string, commentId: string, comment: any) {
        const res = await this.client.put(`/zoho/ticket/comment/${ticketId}/comments/${commentId}`, comment);
        return res.data.data;
    }

    static async updateTicket(ticketId: string, status: any) {
        const res = await this.client.put(`/zoho/ticket/update/${ticketId}`, status);
        return res.data.data;
    }

    static async getTicketAttachments(ticketId: string) {
        const res = await this.client.get<GenericRes<TicketAttachment[]>>(`/zoho/ticket/attachment/${ticketId}`);
        return res.data.data;
    }

    static async uploadTicketAttachment(ticketId: string, formData: FormData) {
        const res = await this.client.post(`/zoho/ticket/attachment/${ticketId}`, formData);
        return res.data.data;
    }

    static async getRoleByRoleId(roleId: string) {
        const res = await this.client.get<GenericRes<RolesRes>>(`/role/${roleId}`);
        return res.data.data;
    }

    static async createRole(body: RolesReq) {
        const res = await this.client.post<GenericRes<RolesRes>>(`/role`, body);
        return res.data.data;
    }

    static async updateRole(body: RolesReq) {
        const res = await this.client.put<GenericRes<RolesRes>>(`/role/${body.id}`, body);
        return res.data.data;
    }
}

export function createReachApiThunk<Returned, ThunkArg, ThunkApiConfig>(
    typePrefix: string,
    callback: (arg: ThunkArg) => AsyncThunkPayloadCreatorReturnValue<Returned, ThunkApiConfig>
) {
    return createAsyncThunk(typePrefix, async (payload: ThunkArg, thunkApi) => {
        try {
            return await callback(payload);
        } catch (error) {
            return thunkApi.rejectWithValue({ ...error.response?.data, txnId: error.config?.headers?.txnId });
        }
    });
}
