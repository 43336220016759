import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { compareDesc, isBefore, isPast, subMonths } from "date-fns";
import { AlertActivityType } from "../screens/alerts-history-page";
import { parseExpiry } from "../utils/helpers";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import {
    AccountSwapReq,
    AddCreditReq,
    AddonChargeReq,
    AutoTopupReq,
    CDRReportReq,
    ChangePlanReq,
    CreditCard,
    CustomerProfile,
    HubspotEngagementNotesReq,
    CreditPurchaseReq,
    ManageFeatureReq,
    NewToPortReq,
    TaxType,
    TopupReq,
    UpdatePortoutReq,
    UpdatePortReq,
    PayInAdvanceReq,
    InstantChargeReq,
    PaymentPageReq,
} from "../utils/types";
import { Customer } from "../utils/types/customer";
import { reset } from "./auth-slice";

export enum CardExpiryState {
    VALID,
    ALMOST_EXPIRED,
    EXPIRED,
}

export interface ParsedCreditCard extends CreditCard {
    parsedDate: number;
    cardExpiryState?: CardExpiryState;
}

const profilesAdapter = createEntityAdapter<CustomerProfile>();

export enum ProfileState {
    LOADING,
    ERROR,
    LOADED,
}

export const sendEngagementNotes = createReachApiThunk(
    "profiles/sendEngagementNotes",
    async (payload: HubspotEngagementNotesReq) => {
        const res = await ReachCsAPI.sendEngagementNotes(payload);
        return res;
    }
);

export const updateHubspot = createReachApiThunk(
    "profiles/updateHubspot",
    async (payload: { email: string } & Record<string, string | undefined>) => {
        const res = await ReachCsAPI.updateHubspot(payload);
        return res;
    }
);

export const getCDRReports = createReachApiThunk("profiles/getCDRReports", async (customerId: string) => {
    const res = await ReachCsAPI.getCDRReports(customerId);
    return res;
});

export const getPlanHistory = createReachApiThunk("profiles/getPlanHistory", (customerId: string) =>
    ReachCsAPI.getPlanHistory(customerId)
);

export const cancelReachPlan = createReachApiThunk("profiles/cancelReachPlan", (customerId: string) =>
    ReachCsAPI.cancelReachPlan(customerId)
);

export const deactivateIld = createReachApiThunk(
    "profiles/deactivateIld",
    async ({
        primaryId,
        customerId,
        adjustCreditInBill,
    }: {
        primaryId: string;
        customerId: string;
        adjustCreditInBill: boolean;
    }) => {
        const res = await ReachCsAPI.deactivateIld(primaryId, customerId, adjustCreditInBill);
        return res;
    }
);

export const activateIld = createReachApiThunk(
    "profiles/activateIld",
    async ({ primaryId, customerId }: { primaryId: string; customerId: string }) => {
        const res = await ReachCsAPI.activateIld(primaryId, customerId);
        return res;
    }
);

export const updatePortout = createReachApiThunk(
    "profiles/updatePortout",
    async ({ customerId, payload }: { customerId: string; payload: UpdatePortoutReq }) => {
        const res = await ReachCsAPI.updatePortout(customerId, payload);
        return res;
    }
);

export const purchaseILDCredit = createReachApiThunk(
    "profiles/purchaseILDCredit",
    async (payload: CreditPurchaseReq) => {
        const res = await ReachCsAPI.purchaseCredit(payload);
        return res;
    }
);

export const getInternationalCountryList = createReachApiThunk(
    "profiles/getInternationalCountryList",
    async (mvne: string) => {
        const res = await ReachCsAPI.getInternationalCountryList(mvne);
        return res;
    }
);

export const getIldOverallUsage = createReachApiThunk("profiles/getIldOverallUsage", async (groupId: string) => {
    const res = await ReachCsAPI.getIldOverallUsage(groupId);
    return res;
});

export const getIldCreditStatus = createReachApiThunk("profiles/getIldCreditStatus", async (groupId: string) => {
    const res = await ReachCsAPI.getIldCreditStatus(groupId);
    return res;
});

export const generateCDRReport = createReachApiThunk(
    "profiles/generateCDRReport",
    async ({ customerId, ...payload }: { customerId: string } & CDRReportReq) => {
        const res = await ReachCsAPI.generateCDRReport(customerId, payload);
        return res;
    }
);

export const updateProfile = createReachApiThunk(
    "profiles/updateProfile",
    async ({ id, customer }: { id: string; customer: Partial<Customer> }) => {
        const res = await ReachCsAPI.updateAccount(id, customer, process.env.MOCK_ACTIVATION ? "DATAMI" : "TELISPIRE");
        return res;
    }
);

export const addCredit = createReachApiThunk("profiles/addCredit", async (payload: AddCreditReq) => {
    const res = await ReachCsAPI.addCredit(payload);
    return res;
});

export const getMDNSwapStatus = createReachApiThunk("profiles/getMDNSwapStatus", async (customerId: string) => {
    const res = await ReachCsAPI.getMDNSwapStatus(customerId);
    return res;
});

export const generateNewMDN = createReachApiThunk(
    "profiles/generateNewMDN",
    async ({ customerId, zipCode, chargable }: { customerId: string; zipCode: string; chargable: boolean }) => {
        const res = await ReachCsAPI.generateNewMDN(customerId, zipCode, chargable);
        return res;
    }
);

export const newToPortNumber = createReachApiThunk(
    "profiles/newToPortNumber",
    async ({ customerId, chargable, ...payload }: { customerId: string; chargable: boolean } & NewToPortReq) =>
        ReachCsAPI.newToPortNumber(customerId, chargable, payload)
);

export const updateVMPassword = createReachApiThunk(
    "profiles/updateVMPassword",
    async ({ customerId, password }: { customerId: string; password: string }) => {
        const res = await ReachCsAPI.changeVoicemailPassword(customerId, password);
        return res;
    }
);

export const setCallerIdStatus = createReachApiThunk(
    "profiles/setCallerIdStatus",
    async ({ customerId, isBlocked }: { customerId: string; isBlocked: boolean }) => {
        const res = await ReachCsAPI.setCallerIdStatus(customerId, isBlocked);
        return res;
    }
);

export const swapAccount = createReachApiThunk("profiles/swapAccount", async (payload: AccountSwapReq) => {
    const res = await ReachCsAPI.swapAccount(payload);
    return res;
});

export const addAddonCharge = createReachApiThunk("profiles/addAddonCharge", async (payload: AddonChargeReq) =>
    ReachCsAPI.addAddonCharge(payload)
);

export const payInAdvance = createReachApiThunk("profiles/payInAdvance", async (payload: PayInAdvanceReq) =>
    ReachCsAPI.payInAdvance(payload)
);

export const addInstantCharge = createReachApiThunk("profiles/addInstantCharge", async (payload: InstantChargeReq) =>
    ReachCsAPI.addInstantCharge(payload)
);

export const getDiscountAnalytics = createReachApiThunk("profiles/getDiscountAnalytics", async (customerId: string) => {
    const res = await ReachCsAPI.getDiscountAnalytics(customerId);
    return res;
});

export const manageCDMAless = createReachApiThunk(
    "profiles/manageCDMAless",
    async ({ customerId, enable }: { customerId: string; enable: boolean }) => {
        const res = await ReachCsAPI.manageCDMAless(customerId, enable);
        return res;
    }
);

export const getPortoutInfo = createReachApiThunk("profiles/getPortoutInfo", async (customerId: string) => {
    const res = await ReachCsAPI.getPortoutInfo(customerId);
    return res;
});

export const getGroupReferralCredits = createReachApiThunk(
    "profiles/getGroupReferralCredits",
    async (groupId: string) => {
        const res = await ReachCsAPI.getGroupReferralCredits(groupId);
        return res;
    }
);

export const resetNetwork = createReachApiThunk("profiles/resetNetwork", async (customerId: string) => {
    const res = await ReachCsAPI.resetNetwork(customerId);
    return res;
});

export const getNetworkResetStatus = createReachApiThunk(
    "profiles/getNetworkResetStatus",
    async (customerId: string) => {
        const res = await ReachCsAPI.getNetworkResetStatus(customerId);
        return res;
    }
);

export const getTopupHistory = createReachApiThunk("profiles/getTopupHistory", async (groupId: string) => {
    const res = await ReachCsAPI.getTopupHistory(groupId);
    return res;
});

export const setSimSwap = createReachApiThunk(
    "profiles/setSimSwap",
    async ({ customerId, simId }: { customerId: string; simId: string }) => {
        const res = await ReachCsAPI.setSimSwap(customerId, simId);
        return res;
    }
);

export const addTopupInfo = createReachApiThunk(
    "profiles/addTopup",
    async ({ primaryCustomerId, ...payload }: TopupReq & { primaryCustomerId: string }) => {
        const res = await ReachCsAPI.addTopup(primaryCustomerId, payload);
        return res;
    }
);

export const getTaxByCustomer = createReachApiThunk(
    "profiles/getTaxByCustomer",
    async (payload: { amount: string; type: TaxType; customerId: string; additionalLines: number }) => {
        const res = await ReachCsAPI.getTaxByCustomer(payload);
        return res;
    }
);

export const manageFeature = createReachApiThunk(
    "profiles/manageFeature",
    async ({ customerId, payload }: { customerId: string; payload: ManageFeatureReq }) => {
        const res = await ReachCsAPI.manageFeature(customerId, payload);
        return res;
    }
);

export const cancelTopup = createReachApiThunk("profiles/cancelTopup", async (customerId: string) => {
    const res = await ReachCsAPI.cancelTopup(customerId);
    return res;
});

export const refundTopup = createReachApiThunk("profiles/refundTopup", async (customerId: string) => {
    const res = await ReachCsAPI.refundTopup(customerId);
    return res;
});

export const updateAutoTopup = createReachApiThunk("profiles/updateAutoTopup", async (payload: AutoTopupReq) => {
    const res = await ReachCsAPI.updateAutoTopup(payload);
    return res;
});

export const changePlan = createReachApiThunk("profiles/changePlan", async (payload: ChangePlanReq) => {
    const res = await ReachCsAPI.changePlan(payload);
    return res;
});

export const updatePort = createReachApiThunk(
    "profiles/updatePort",
    async ({ customerId, ...payload }: UpdatePortReq & { customerId: string }) => {
        const res = await ReachCsAPI.updatePort(customerId, payload);
        return res;
    }
);

export const getActivation = createReachApiThunk("profiles/getActivation", async (customerId: string) => {
    const res = await ReachCsAPI.getActivation(customerId);
    return res;
});

export const generatePaymentPage = createReachApiThunk(
    "profiles/generatePaymentPage",
    async (payload: PaymentPageReq) => {
        const res = await ReachCsAPI.generatePaymentPage(payload);
        return res;
    }
);

export const getAlertsHistory = createReachApiThunk(
    "profiles/getAlertsHistory",
    ({ customerId, startDate, endDate }: { customerId: string; startDate: string; endDate: string }) =>
        ReachCsAPI.getAlertsHistory(customerId, startDate, endDate)
);

export const getPromisetoPayHistory = createReachApiThunk(
    "profiles/getPromisetoPayHistory",
    ({ customerId, startDate, endDate }: { customerId: string; startDate: string; endDate: string }) =>
        ReachCsAPI.getPromisetopayHistory(customerId, startDate, endDate)
);

export const getAccountHistory = createReachApiThunk(
    "profiles/getAccountHistory",
    ({ customerId, startDate, endDate }: { customerId: string; startDate: string; endDate: string }) =>
        ReachCsAPI.getAccountHistory(customerId, startDate, endDate)
);

export const rectifyMDNAlert = createReachApiThunk(
    "profiles/rectifyMDNAlert",
    ({
        customerId,
        payload,
    }: {
        customerId: string;
        payload: { operation: keyof typeof AlertActivityType; mode: "manual" | "automatic" };
    }) => ReachCsAPI.rectifyMDNAlert(customerId, payload)
);

export const cancelOrder = createReachApiThunk(
    "profiles/cancelOrder",
    async ({
        customerId,
        orderIds,
        reason,
        reimburse,
    }: {
        customerId: string;
        orderIds: string[];
        reason: string;
        reimburse: boolean;
    }) => {
        const res = await ReachCsAPI.cancelOrder(customerId, orderIds, reason, reimburse);
        return res;
    }
);

export const fetchProfile = createAsyncThunk("profiles/fetchProfile", async (id: string) => {
    const res = await ReachCsAPI.fetchProfile(id);
    return res;
});

export const setAutopayCard = createReachApiThunk("profiles/setAutopayCard", async (uuid: string) => {
    const res = await ReachCsAPI.setAutopayCard(uuid);
    return res;
});

export const removeCard = createReachApiThunk("profiles/removeCard", async (uuid: string) => {
    const res = await ReachCsAPI.removeCard(uuid);
    return res;
});

export const sendPaymentLink = createAsyncThunk("profiles/sendPaymentLink", async (customerId: string) => {
    const res = await ReachCsAPI.sendPaymentLink(customerId);
    return res;
});

export const addCreditCard = createReachApiThunk("profiles/addCreditCard", async (customerId: string) => {
    const res = await ReachCsAPI.addCreditCard(customerId);
    return res;
});

export const sendTemporaryPassword = createReachApiThunk("profiles/sendTemporaryPassword", (emailId: string) =>
    ReachCsAPI.sendTemporaryPassword(emailId)
);

export const updateCardExpiry = createReachApiThunk(
    "profiles/updateCardExpiry",
    async ({ ccId, expiry }: { ccId: string; expiry: string }) => {
        const res = await ReachCsAPI.updateCardExpiry(ccId, expiry);
        return res;
    }
);

export const checkIMEIInfo = createAsyncThunk(
    "profiles/checkIMEIInfo",
    async ({ imei, reachMvne }: { imei: string; reachMvne: string }) => {
        const res = await ReachCsAPI.checkIMEIInfo(imei, reachMvne);
        return res;
    }
);

export const checkIMEI = createAsyncThunk(
    "profiles/checkIMEI",
    async ({ imei, reachMvne }: { imei: string; reachMvne: string }) => {
        const res = await ReachCsAPI.checkIMEI(imei, reachMvne);
        return res;
    }
);

export const validateIccIdByMvne = createAsyncThunk(
    "profiles/validateIccIdByMvne",
    async ({ iccId, mvne }: { iccId: string; mvne: string }) => {
        const res = await ReachCsAPI.validateIccIdByMvne(iccId, mvne);
        return res;
    }
);

interface ProfileAdditionalState {
    profileState: ProfileState;
    error?: string;
}

export const profileSlice = createSlice({
    name: "profiles",
    initialState: profilesAdapter.getInitialState<ProfileAdditionalState>({
        profileState: ProfileState.LOADING,
        error: undefined,
    }),
    reducers: {
        profileAdded: profilesAdapter.upsertOne,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfile.fulfilled, (state, action) => {
                state.profileState = ProfileState.LOADED;
                state.error = undefined;
                action.payload.creditCards = action.payload.creditCards
                    ?.map(
                        (card): ParsedCreditCard => ({
                            ...card,
                            nameOnCard: card.nameOnCard.replace(/\+/g, " "),
                            parsedDate: parseExpiry(card.dateOfExpiry).getTime(),
                        })
                    )
                    .map(
                        (card): ParsedCreditCard => ({
                            ...card,
                            cardExpiryState: isPast(card.parsedDate!)
                                ? CardExpiryState.EXPIRED
                                : isBefore(subMonths(card.parsedDate!, 1), new Date())
                                ? CardExpiryState.ALMOST_EXPIRED
                                : CardExpiryState.VALID,
                        })
                    )
                    .sort((a, b) => {
                        if (a.isAutoPay) {
                            return -1;
                        }

                        if (b.isAutoPay) {
                            return 1;
                        }

                        return compareDesc(new Date(a.createdDate), new Date(b.createdDate));
                    });
                profilesAdapter.removeOne(state, action.payload.id);
                profilesAdapter.addOne(state, action.payload);
            })
            .addCase(fetchProfile.pending, (state) => {
                state.profileState = ProfileState.LOADING;
                state.error = undefined;
            })
            .addCase(fetchProfile.rejected, (state, action) => {
                state.profileState = ProfileState.ERROR;
                state.error = action.error.message;
            })
            .addCase(reset, () =>
                profilesAdapter.getInitialState<ProfileAdditionalState>({
                    profileState: ProfileState.LOADING,
                    error: undefined,
                })
            );
    },
});

export const { profileAdded } = profileSlice.actions;

export const profilesSelector = profilesAdapter.getSelectors((state: RootState) => state.profiles);

export const profileStateSelector = (state: RootState) => ({
    profileState: state.profiles.profileState,
    error: state.profiles.error,
});
