import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { CreateUserReq, SearchableUser, SearchCustomerLastKey, SearchUserReq } from "../utils/types";
import { reset } from "./auth-slice";

const usersAdapter = createEntityAdapter<SearchableUser>({
    selectId: (user) => user.emailId,
});

interface AdditionalSliceState {
    lastKeys?: SearchCustomerLastKey;
    status?: number;
    loading: boolean;
    loadingAdditional: boolean;
    error?: string;
}

interface UserSearchPayload extends SearchUserReq {
    update: boolean;
}

export const searchUsers = createReachApiThunk(
    "users/searchUsers",
    async ({ update, ...payload }: UserSearchPayload) => {
        const res = await ReachCsAPI.searchUsers(payload);
        return res;
    }
);

export const getRolesByEmailId = createReachApiThunk("users/getRolesByEmailId", async (email: string) => {
    const res = await ReachCsAPI.getRolesByEmailId(email);
    return res;
});

export const createUser = createReachApiThunk("users/createUser", async (payload: CreateUserReq) =>
    ReachCsAPI.createUser(payload)
);

export const updateUser = createReachApiThunk("users/updateUser", async (payload: CreateUserReq) =>
    ReachCsAPI.updateUser(payload)
);

const additionalInitialState: AdditionalSliceState = {
    loading: true,
    loadingAdditional: false,
};

export const usersSlice = createSlice({
    name: "users",
    initialState: usersAdapter.getInitialState(additionalInitialState),
    reducers: {
        usersInserted: usersAdapter.setAll,
        usersUpdated: usersAdapter.upsertMany,
        queryUpdated(state) {
            state.lastKeys = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchUsers.fulfilled, (state, action) => {
                if (action.meta.arg.update) {
                    usersAdapter.upsertMany(state, action.payload.results);
                } else {
                    usersAdapter.setAll(state, action.payload.results);
                }
                state.lastKeys = action.payload.lastKeys;
                state.loading = false;
                state.loadingAdditional = false;
                state.error = undefined;
            })
            .addCase(searchUsers.pending, (state, action) => {
                if (action.meta.arg.update) {
                    state.loadingAdditional = true;
                } else {
                    state.loading = true;
                }
                state.error = undefined;
            })
            .addCase(searchUsers.rejected, (state, action) => {
                state.loading = false;
                state.loadingAdditional = false;
                state.error = "Error";
            })
            .addCase(getRolesByEmailId.fulfilled, (state, action) => {
                usersAdapter.upsertOne(state, action.payload);
            })
            .addCase(createUser.fulfilled, (state, action) => {
                usersAdapter.upsertOne(state, action.payload);
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                usersAdapter.upsertOne(state, action.payload);
            })
            .addCase(reset, () => usersAdapter.getInitialState(additionalInitialState));
    },
});

export const { usersUpdated, usersInserted, queryUpdated } = usersSlice.actions;

export const usersSelector = usersAdapter.getSelectors((state: RootState) => state.users);

export const userSearchSelector = createSelector(
    (state: RootState) => state.users,
    (state) => ({
        loading: state.loading,
        lastKeys: state.lastKeys,
        loadingAdditional: state.loadingAdditional,
    })
);
