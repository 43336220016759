import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { UserRolesRes } from "../utils/types/user-roles";
import { reset } from "./auth-slice";

interface UserRoleSliceState {
    accountId?: string;
    response?: UserRolesRes;
    userRolesLoading: boolean;
    error?: string;
}

const initialState: UserRoleSliceState = {
    userRolesLoading: true,
};

export const getRolesByEmailId = createAsyncThunk("userRoles/getRolesByEmailId", async (email: string) => {
    const res = await ReachCsAPI.getRolesByEmailId(email);
    return res;
});

export const userRolesSlice = createSlice({
    name: "userRoles",
    initialState,
    reducers: {
        accountIdUpdated: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload) {
                state.accountId = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRolesByEmailId.fulfilled, (state, action) => {
                state.response = action.payload;
                state.userRolesLoading = false;
                state.error = undefined;
            })
            .addCase(getRolesByEmailId.pending, (state) => {
                state.userRolesLoading = true;
                state.error = undefined;
            })
            .addCase(getRolesByEmailId.rejected, (state, action) => {
                state.error = action.error.code;
                state.userRolesLoading = false;
            })
            .addCase(reset, () => initialState);
    },
});

export const { accountIdUpdated } = userRolesSlice.actions;

export const userRolesSelector = (state: RootState) => state.userRoles;

export const selectUserInfo = createSelector(userRolesSelector, (userRoles) => ({
    firstName: userRoles?.response?.firstName,
    lastName: userRoles?.response?.lastName,
    emailId: userRoles?.response?.emailId,
    phoneNumber: userRoles?.response?.phoneNumber,
    roleIds: userRoles?.response?.roleIds,
    managerId: userRoles?.response?.managerId,
    accountId: userRoles?.response?.accountId,
    roleMap: userRoles?.response?.roleMap,
    supportDepartmentId: userRoles?.response?.supportDepartmentId,
}));
