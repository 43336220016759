import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../utils/store";

const initialState: { globalError?: Error | { message?: string | string[]; txnId?: string } } = {};

export const globalErrorSlice = createSlice({
    name: "globalError",
    initialState,
    reducers: {
        setSnackbarError(state, action: PayloadAction<Error | { message?: string | string[] } | undefined>) {
            state.globalError = action.payload;
        },
    },
});

export const { setSnackbarError } = globalErrorSlice.actions;

export const globalErrorSelector = (state: RootState) => state.globalError?.globalError;
