import React from "react";
import { Link } from "gatsby";
import { Dropdown, NavItem, NavLink } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AuthState, authStateSelector } from "../features/auth-slice";
import brandLogo from "../images/brandLogo.png";
import { useAppSelector } from "../utils/store";
import { selectUserInfo } from "../features/user-role-slice";

export interface NavbarProps {
    onSignOut: () => void;
}

export function Navbar(props: NavbarProps) {
    const auth = useAppSelector(authStateSelector);
    const userInfo = useAppSelector(selectUserInfo);

    return (
        <nav className="navbar navbar-light bg-white shadow-sm">
            <Link className="navbar-brand" to="/">
                <img src={brandLogo} height={45} />
            </Link>
            {/* Hide icons if there is no user info available */}
            {(userInfo.emailId || auth?.authState === AuthState.LOGGED_IN) && (
                <div className="d-flex align-items-center">
                    <span style={{ fontSize: 24, marginRight: 24 }} className="reach-cs-notification" />
                    <Dropdown as={NavItem}>
                        <Dropdown.Toggle as="div" bsPrefix="cursor-pointer" className="d-flex align-items-center">
                            <span style={{ fontSize: 24 }} className="reach-cs-profile" />
                            <span className="font-family-semibold mx-2">{userInfo.firstName}</span>
                            <span className="reach-cs-arrow-down" style={{ fontSize: 12 }} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={props.onSignOut}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )}
        </nav>
    );
}
