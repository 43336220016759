import { AxiosInstance } from "axios";
import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import { persistor, store } from "./store";
import { isReachSSOEnabled, isSSOEnabled } from "./helpers";
import { reset, signOut } from "../features/auth-slice";

export function getAuthToken(): Promise<string> | null {
    const user = firebase.auth().currentUser;

    if (user) {
        return user.getIdToken();
    } else {
        // Should never be null because of anonymous login
        return null;
    }
}

export function setupRequestInterceptor(client: AxiosInstance) {
    client.interceptors.request.use(
        async (config) => {
            const uuid = uuidv4();
            let token = await getAuthToken();
            const state = store.getState();
            const isReachLogin = localStorage.getItem("isReachLogin");
            config.headers = {
                ...config.headers,
                txnId: uuid,
                "x-account-src": process.env.X_ACCOUNT_SRC,
            };

            if (token) {
                if (isSSOEnabled() || isReachSSOEnabled()) {
                    config.headers.Authorization = `Bearer false|${token}`;
                } else {
                    config.headers.Authorization = `Bearer ${token}`;
                }
            } else if (localStorage.getItem("id_token")) {
                token = localStorage.getItem("id_token");
                if (isReachLogin === "true") {
                    config.headers.Authorization = `Bearer custom|${token}`;
                } else if (isReachLogin === "false") {
                    config.headers.Authorization = `Bearer true|${token}`;
                } else {
                    config.headers.Authorization = `Bearer ${token}`;
                }
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

export function setupResponseInterceptor(client: AxiosInstance) {
    client.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error?.response?.status == 401 || error?.response?.status == 403) {
                const ssoToken = localStorage.getItem("id_token");
                const isReachLogin = localStorage.getItem("isReachLogin");
                if (!isReachSSOEnabled()) {
                    await store.dispatch(signOut());
                }
                store.dispatch(reset());
                persistor.purge();
                localStorage.clear();
                sessionStorage.clear();
                localStorage.setItem("is_timeout", "true");
                if (ssoToken) {
                    if (isReachLogin) {
                        window.location.href = process.env.SSO_REACH_LOGOUT_URL;
                    } else {
                        window.location.href = process.env.SSO_LOGOUT_URL;
                    }
                } else {
                    window.location.href = "/";
                }
            }
            return Promise.reject(error);
        }
    );
}
