export interface Data {
    size: number;
    unit: string;
}

const units = ["B", "KB", "MB", "GB", "TB"];

export function getClosestSize(size: number): Data {
    if (size === 0) {
        return { size: 0, unit: units[0] };
    }
    const i = Math.floor(Math.log(size) / Math.log(1024));
    const decs = units[i] === "GB" ? 1 : 0;
    return {
        size: +(size / Math.pow(1024, i)).toFixed(decs) * 1,
        unit: units[i],
    };
}

export function getBytes(size: number, unit: string): number {
    for (let i = 0; i < units.length; i++) {
        if (units[i] === unit) {
            return size * 1024 ** i;
        }
    }

    return size;
}
