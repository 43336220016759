import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import firebase from "firebase/app";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { ForgotPasswordReq } from "../utils/types";

interface AuthSliceState {
    authState: AuthState;
    emailId?: string | null;
    displayName?: string | null;
    isReachLogin?: boolean | null;
}

export const reset = createAction("reset");

export enum AuthState {
    INITIAL,
    LOGGED_OUT,
    LOGGED_IN,
    MFA,
}

const initialState: AuthSliceState = {
    authState: AuthState.INITIAL,
    isReachLogin: null,
};

export const getTenantId = createReachApiThunk("auth/getTenantId", (accountId: string) =>
    ReachCsAPI.getTenantId(accountId)
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
    await firebase.auth().signOut();
});

export const forgotPassword = createReachApiThunk(
    "profiles/forgotPassword",

    async (payload: ForgotPasswordReq) => {
        const res = await ReachCsAPI.forgotPassword(payload);

        return res;
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        userUpdated(state, action: PayloadAction<Omit<AuthSliceState, "authState">>) {
            const user = action.payload;
            state.emailId = user?.emailId;
            state.displayName = user?.displayName;
            if (state.emailId) {
                state.authState = AuthState.LOGGED_IN;
            } else {
                state.authState = AuthState.LOGGED_OUT;
            }
        },
        authStateUpdated(state, action: PayloadAction<AuthState>) {
            state.authState = action.payload;
        },
        loginWayUpdated(state, action: PayloadAction<any>) {
            state.isReachLogin = action.payload;
        },

        loginWayReset(state) {
            state.isReachLogin = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signOut.fulfilled, (state) => {
                state.authState = AuthState.LOGGED_OUT;
                state.emailId = undefined;
            })
            .addCase(reset, () => initialState);
    },
});

export const { userUpdated, authStateUpdated, loginWayUpdated, loginWayReset } = authSlice.actions;

export const authStateSelector = (state: RootState) => state.auth;
