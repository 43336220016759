import { createSelector } from "reselect";
import { useAppSelector } from "../../utils/store";
import { Limitations, PermissionKey } from "../../utils/types/user-roles";
import { userRolesSelector } from "../user-role-slice";

const userPermissionsSelector = createSelector(userRolesSelector, (userRoles) => userRoles.response?.permissions);

const limitationSelector = createSelector(userRolesSelector, (userRoles) => userRoles.response?.limitations);

export function usePermissions() {
    const permissions = useAppSelector(userPermissionsSelector);
    const limitations = useAppSelector(limitationSelector);

    /**
     *
     * @param permissions List of permissions
     * @returns If all of the permissions is satisfied
     */
    function hasAllPermissions(...perms: PermissionKey[]) {
        return perms.every((permission) => permissions?.[permission]);
    }

    /**
     *
     * @param permissions List of permissions
     * @returns If atleast one of the permissions is satisfied
     */
    function hasOnePermission(...perms: PermissionKey[]) {
        return perms.some((permission) => permissions?.[permission]);
    }

    /**
     *
     * @param permission Permission
     * @returns The limitations of the permission
     */
    function getLimitations<T extends keyof Limitations>(permission: T): Limitations[T] {
        return limitations?.[permission];
    }

    return { permissions, hasOnePermission, hasAllPermissions, getLimitations };
}
