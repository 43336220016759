import React from "react";
import { Provider } from "react-redux";
import { AuthProvider } from "./firebase";
import { persistor, store } from "./utils/store";
import { ViewportProvider } from "./utils/viewport-context";
import { PersistGate } from "redux-persist/integration/react";
import { ZohoChatProvider } from "./components/zoho-chat-provider";

export const wrapRootElement = ({ element }: any) => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <AuthProvider>
                <ZohoChatProvider>
                    <ViewportProvider>{element}</ViewportProvider>
                </ZohoChatProvider>
            </AuthProvider>
        </PersistGate>
    </Provider>
);
