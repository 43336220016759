import {
    AsyncThunkAction,
    combineReducers,
    configureStore,
    getDefaultMiddleware,
    unwrapResult,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { authSlice } from "../features/auth-slice";
import { customersSlice } from "../features/customers-slice";
import { purchaseSlice } from "../features/purchase-slice";
import { userRolesSlice } from "../features/user-role-slice";
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    KEY_PREFIX,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { plansSlice } from "../features/plans-slice";
import { configSlice } from "../features/config-slice";
import { profileSlice } from "../features/profile-slice";
import { activationSlice } from "../features/activation-slice";
import { globalErrorSlice } from "../features/global-error-slice";
import { dataUsageSlice } from "../features/data-usage-slice";
import { billingSlice } from "../features/billing-slice";
import { snackbarSlice } from "../features/snackbar-slice";
import { additionalLinesPurchaseSlice } from "../features/additional-lines-purchase-slice";
import { leavingReachSlice } from "../features/leaving-reach-slice";
import { useCallback } from "react";
import { setupRequestInterceptor, setupResponseInterceptor } from "./api-client";
import { ReachCsAPI } from "./reach-cs-api";
import { usersSlice } from "../features/operation-users-slice";
import { PersistConfig, Persistor } from "redux-persist/es/types";
import { Store } from "redux";
import { isBrowser } from "./helpers";
import { intlRoamingSlice } from "../features/intl-roaming-slice";
import { irPurchaseSlice } from "../features/ir-purchase-slice";
import { intlCountriesSlice } from "../features/intl-countries-slice";
import { ticketsSlice } from "../features/tickets-slice";
import { rolesSlice } from "../features/operation-roles-slice";
import { allDepTicketSlice } from "../features/all-tickets-slice";

export const rootReducer = combineReducers({
    auth: authSlice.reducer,
    userRoles: userRolesSlice.reducer,
    customers: customersSlice.reducer,
    profiles: profileSlice.reducer,
    purchase: purchaseSlice.reducer,
    plans: plansSlice.reducer,
    config: configSlice.reducer,
    activation: activationSlice.reducer,
    globalError: globalErrorSlice.reducer,
    additionalLinesPurchase: additionalLinesPurchaseSlice.reducer,
    dataUsage: dataUsageSlice.reducer,
    billing: billingSlice.reducer,
    snackbar: snackbarSlice.reducer,
    leavingReach: leavingReachSlice.reducer,
    users: usersSlice.reducer,
    roles: rolesSlice.reducer,
    intlRoaming: intlRoamingSlice.reducer,
    irPurchase: irPurchaseSlice.reducer,
    intlCountries: intlCountriesSlice.reducer,
    tickets: ticketsSlice.reducer,
    allDepTickets: allDepTicketSlice.reducer,
});

const persistConfig = {
    key: "root",
    storage,
    version: 1,
    blacklist: ["auth", "globalError", "snackbar"],
};

export const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store);
crosstabSync(store, persistConfig, { whitelist: ["userRoles"] });

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useUnwrapAsyncThunk = () => {
    const dispatch = useAppDispatch();
    return useCallback(
        <R extends any>(asyncThunk: AsyncThunkAction<R, any, any>): Promise<R> =>
            dispatch(asyncThunk).then(unwrapResult),
        [dispatch]
    );
};

setupRequestInterceptor(ReachCsAPI.client);
setupResponseInterceptor(ReachCsAPI.client);

type CrosstabConfig = {
    blacklist?: string[];
    keyPrefix?: string;
    whitelist?: string[];
};

function crosstabSync(store: Store, persistConfig: PersistConfig<any>, crosstabConfig: CrosstabConfig = {}) {
    const blacklist: string[] | null = crosstabConfig.blacklist || null;
    const whitelist: string[] | null = crosstabConfig.whitelist || null;
    const keyPrefix: string = crosstabConfig.keyPrefix || KEY_PREFIX;

    const { key }: { key: string } = persistConfig;

    if (!isBrowser) {
        return;
    }

    window.addEventListener("storage", handleStorageEvent, false);

    function handleStorageEvent(e: StorageEvent) {
        if (e.key && e.key.indexOf(keyPrefix) === 0) {
            if (e.oldValue === e.newValue) {
                return;
            }

            const statePartial: Record<string, string> = JSON.parse(e.newValue || "{}");
            const statePartialOld: Record<string, string> = JSON.parse(e.oldValue || "{}");

            if (whitelist && whitelist.indexOf("userRoles") >= 0) {
                const userRoles = JSON.parse(statePartial["userRoles"] || "{}");
                const userRolesOld = JSON.parse(statePartialOld["userRoles"] || "{}");
                if (userRoles.accountId === userRolesOld.accountId) {
                    return;
                }
            }

            const state: any = Object.keys(statePartial).reduce((state, reducerKey) => {
                if (whitelist && whitelist.indexOf(reducerKey) === -1) {
                    return state;
                }
                if (blacklist && blacklist.indexOf(reducerKey) !== -1) {
                    return state;
                }

                state[reducerKey] = JSON.parse(statePartial[reducerKey]);

                return state;
            }, {} as any);

            store.dispatch({
                key,
                payload: state,
                type: REHYDRATE,
            });
        }
    }
}
