import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { format } from "date-fns";
import { Link, navigate } from "gatsby";
import { Button } from "../../components/button";
import CustomerBadge from "../../components/customer-badge";
import { useErrorHandler } from "../../components/error-snackbar";
import Layout from "../../components/layout";
import Modal from "../../components/modal";
import Select from "../../components/select";
import { getInternationalCountryList } from "../../features/intl-countries-slice";
import { getIntlTrips, selectAllowedCustomers, selectTrips } from "../../features/intl-roaming-slice";
import { profilesSelector } from "../../features/profile-slice";
import { usePermissions } from "../../features/hooks/use-permissions";
import infoIcon from "../../images/info-icon.png";
import { getUTCDate } from "../../utils/helpers";
import { useAppSelector, useUnwrapAsyncThunk } from "../../utils/store";
import { TripStatus } from "../../utils/types";
import { useViewport, ViewportBreakpoint } from "../../utils/viewport-context";

export interface ManageIRProps extends RouteComponentProps {
    customerId: string;
}

export enum TripFilter {
    Current = "Current",
    Upcoming = "Upcoming",
    Past = "Past",
}

export default function ManageIR(props: ManageIRProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId));
    const allowedCustomers = useAppSelector(
        selectAllowedCustomers(customerProfile?.groupLines ?? [], customerProfile?.groupId ?? "")
    );

    const handleError = useErrorHandler();
    const unwrap = useUnwrapAsyncThunk();

    const { hasOnePermission } = usePermissions();

    const searchParams = new URLSearchParams(props.location?.search);
    const filterParam = searchParams.get("status") as keyof typeof TripFilter;

    const [filter, setFilter] = useState(
        filterParam && TripFilter[filterParam] ? TripFilter[filterParam] : TripFilter.Current
    );
    const [showTripExceededModal, setShowTripExceededModal] = useState(false);

    const filteredTrips = useAppSelector(selectTrips(customerProfile?.groupId ?? "", filter));

    async function fetchIRTrips() {
        if (customerProfile) {
            try {
                const res = await unwrap(getIntlTrips(customerProfile.groupId));
            } catch (error) {
                console.log(error);
                if (error.resCode !== 40193) {
                    handleError(error);
                }
            }
        }
    }

    useEffect(() => {
        fetchIRTrips();
        async function fetchCountries() {
            try {
                await unwrap(getInternationalCountryList(customerProfile!.source));
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        fetchCountries();
    }, []);

    const { width } = useViewport();

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Manage international roaming`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between pb-3">
                    <h1>Manage international roaming</h1>
                    {hasOnePermission("user_management.intl_roaming.create") && (
                        <Button
                            color="secondary"
                            icon="reach-cs-plus"
                            mini={width < ViewportBreakpoint.MD}
                            // disabled={!hasOnePermission("user_management.intl_roaming.create")}
                            onClick={() => {
                                if (allowedCustomers.length === 0) {
                                    setShowTripExceededModal(true);
                                } else {
                                    navigate(`/ir/purchase/${customerId}`);
                                }
                            }}>
                            Create new trip
                        </Button>
                    )}
                </div>

                <Select
                    className="col-12 col-md-4 col-xl-3 px-0"
                    value={filter}
                    onChange={(event) => setFilter(event.target.value as TripFilter)}>
                    <option value={TripFilter.Current}>Current Trips</option>
                    <option value={TripFilter.Upcoming}>Upcoming Trips</option>
                    <option value={TripFilter.Past}>Past Trips</option>
                </Select>
                <div>
                    {filteredTrips == null ? (
                        <div className="spinner-border text-primary mt-3" />
                    ) : filteredTrips.length === 0 ? (
                        <div className="font-family-semibold mt-3 text-center">
                            No {filter.toLowerCase()} trips found
                        </div>
                    ) : (
                        filteredTrips.map((trip, index) => {
                            const customer = customerProfile.groupLines.find((cust) => cust.id === trip.customerId)!;
                            const countries = trip.countriesMap
                                ? Object.values(trip.countriesMap)
                                      .map((country) => country.countryName)
                                      .join("; ")
                                : "";

                            return (
                                <div
                                    key={trip.id}
                                    className="divider cursor-pointer d-flex align-items-center justify-content-between mt-2 pb-2"
                                    onClick={() => navigate(`/ir/manage/${customerId}/trip/${trip.id}`)}>
                                    <div>
                                        <div>
                                            {customer.firstName} {customer.lastName}
                                        </div>
                                        <div style={{ fontSize: 12 }}>{countries}</div>
                                    </div>
                                    <div className="d-flex flex-column align-items-end justify-content-center font-family-semibold">
                                        {trip.activationInProgress && (
                                            <div>
                                                <div className="spinner-border spinner-border-sm text-primary mr-2" />
                                                <span className="text-primary">In Progress</span>
                                            </div>
                                        )}
                                        {!trip.activationInProgress && trip.status === TripStatus.ACTIVATED && (
                                            <div className="text-primary">Active</div>
                                        )}
                                        {!trip.activationInProgress && trip.status === TripStatus.INACTIVE && (
                                            <div>
                                                {!trip.prepaid || (trip.availableCredits && trip.availableCredits > 0)
                                                    ? "Scheduled"
                                                    : "Draft"}
                                            </div>
                                        )}
                                        {!trip.activationInProgress &&
                                            trip.status === TripStatus.DEACTIVATED &&
                                            trip.deactivationReason &&
                                            !trip.endedReason && (
                                                <div className="text-error">Temporarily deactivated</div>
                                            )}
                                        {!trip.activationInProgress &&
                                            trip.status === TripStatus.ACTIVATION_FAILED &&
                                            trip.activationFailureReason && (
                                                <div className="text-error">Temporarily deactivated</div>
                                            )}
                                        <div style={{ fontSize: 12 }}>
                                            {format(
                                                getUTCDate(new Date(trip.startDate || trip.tentativeStartDate!)),
                                                "MMMM d, yyyy"
                                            )}
                                        </div>
                                        {hasOnePermission("user_management.intl_roaming.update") && (
                                            <div className="text-cta">
                                                {trip.status === TripStatus.COMPLETED
                                                    ? "View details"
                                                    : !trip.deactivationReason &&
                                                      !trip.activationFailureReason &&
                                                      (customerProfile.isPrimary ? "Manage" : "View")}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
            <Modal title="" show={showTripExceededModal} size="sm" onHide={() => setShowTripExceededModal(false)}>
                <img src={infoIcon} className="mt-n3 mb-3" />
                <div className="font-family-semibold text-center">
                    The maximum number of trips that can be scheduled for this customer has exceeded
                </div>
            </Modal>
        </Layout>
    );
}
