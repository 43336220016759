import React from "react";
import BSModal, { ModalProps as BSModalProps } from "react-bootstrap/Modal";
import classNames from "classnames";

export interface ModalProps extends BSModalProps {
    title: string;
    titleClassName?: string;
}

export default function Modal(props: ModalProps) {
    const { children, title, onHide, titleClassName, ...rest } = props;

    return (
        <BSModal centered onHide={onHide} {...rest}>
            <button
                type="button"
                className="close align-self-start mt-3 ml-3"
                data-dismiss="modal"
                style={{ opacity: 1 }}
                aria-label="Close"
                onClick={onHide}>
                <span aria-hidden="true" className="reach-cs-cross" style={{ fontSize: 14 }} />
            </button>
            <h1 className={classNames("text-center mx-3 m-0", titleClassName)}>{title}</h1>
            <div className="modal-body d-flex flex-column align-items-center">{children}</div>
        </BSModal>
    );
}
