import React, { ReactNode } from "react";
import classNames from "classnames";

export interface BadgeProps {
    className?: string;
    color: string;
    children: ReactNode;
}

export default function Badge(props: BadgeProps) {
    const { className, color, ...rest } = props;

    return <span className={classNames("badge", `badge-${color}`, className)} {...rest} />;
}
