import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { CustomerProfile, OrderReq, ReachPlan, TaxRes, TaxType } from "../utils/types";
import { Address, Customer, ShippingMethod } from "../utils/types/customer";
import { configSelector } from "./config-slice";

interface AdditionalLinesPurchaseSliceState {
    customerProfile?: CustomerProfile;
    groupLines: Customer[];
    additionalCustomers: Partial<Customer>[];
    reachPlan?: ReachPlan;
    isSimInHand: boolean;
    shippingOption?: ShippingMethod;
    shippingAddress?: Address;
    planTaxRes?: TaxRes;
    simTaxRes?: TaxRes;
}

const initialState: AdditionalLinesPurchaseSliceState = {
    additionalCustomers: [],
    groupLines: [],
    isSimInHand: false,
};

export const fetchPlanTax = createReachApiThunk(
    "additionalLinesPurchase/fetchPlanTax",
    async ({ amount, address, additionalLines }: { amount: number; address: Address; additionalLines: number }) => {
        const res = await ReachCsAPI.getTaxByAddress(amount.toFixed(2), TaxType.PLAN, address, additionalLines);
        return res;
    }
);

export const fetchSIMTax = createReachApiThunk(
    "additionalLinesPurchase/fetchSIMTax",
    async ({ amount, address, additionalLines }: { amount: number; address: Address; additionalLines: number }) => {
        const res = await ReachCsAPI.getTaxByAddress(amount.toFixed(2), TaxType.SIM, address, additionalLines);
        return res;
    }
);

export const createOrder = createReachApiThunk("additionalLinesPurchase/createOrder", async (order: OrderReq) => {
    const res = await ReachCsAPI.createOrder(order);
    return res;
});

export const createSecondaryCustomer = createReachApiThunk(
    "additionalLinesPurchase/createSecondaryCustomer",
    async (customer: Customer) => {
        const res = await ReachCsAPI.createCustomer(customer, customer.source);
        return res;
    }
);

export const submitMNPValidate = createReachApiThunk(
    "additionalLinesPurchase/submitMNPValidate",
    async (phoneNumber: string) => {
        const res = await ReachCsAPI.submitMNPValidate(
            phoneNumber,
            true,
            process.env.MOCK_ACTIVATION ? "DATAMI" : "TELISPIRE"
        );
        return res;
    }
);

export const checkIMEI = createReachApiThunk(
    "additionalLinesPurchase/checkIMEI",
    async ({ imei, reachMvne }: { imei: string; reachMvne: string }) => {
        const res = await ReachCsAPI.checkIMEI(imei, reachMvne);
        return res;
    }
);

export const validateMNP = createReachApiThunk("additionalLinesPurchase/validateMNP", async (refNumber: string) => {
    const res = await ReachCsAPI.validateMNP(refNumber);
    return res;
});

export const additionalLinesPurchaseSlice = createSlice({
    name: "additionalLinesPurchase",
    initialState,
    reducers: {
        planTaxReset(state) {
            state.planTaxRes = undefined;
        },
        additionalLineUpdated(state, action: PayloadAction<{ index: number; customer: Partial<Customer> }>) {
            state.additionalCustomers[action.payload.index] = {
                ...state.additionalCustomers[action.payload.index],
                ...action.payload.customer,
            };
        },
        additionalLinesPurchaseReset: () => initialState,
        additionalLinePurchaseTypeUpdated(state, action: PayloadAction<boolean>) {
            state.isSimInHand = action.payload;
        },
        additionalLineGroupUpdated(state, action: PayloadAction<CustomerProfile>) {
            state.customerProfile = action.payload;
            state.groupLines = action.payload.groupLines;
            state.reachPlan = action.payload.reachPlan;
        },
        additionalLineAdded(state, action: PayloadAction<Partial<Customer>>) {
            state.additionalCustomers.push(action.payload);
        },
        additionalLineRemoved(state, action: PayloadAction<number>) {
            state.additionalCustomers.splice(action.payload - 1, 1);
        },
        primaryCustomerAddressesUpdated(state, action: PayloadAction<Address[]>) {
            if (state.customerProfile) {
                state.customerProfile.addresses = action.payload;
            }
        },
        additionalLineShippingUpdated(
            state,
            action: PayloadAction<{ shippingOption: ShippingMethod; shippingAddress: Address }>
        ) {
            state.shippingOption = action.payload.shippingOption;
            state.shippingAddress = action.payload.shippingAddress;
        },
        simTaxSkipped(state) {
            state.simTaxRes = {
                amount: 0,
                totalTax: 0,
                taxes: [],
                taxId: "",
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPlanTax.fulfilled, (state, action) => {
                state.planTaxRes = action.payload;
            })
            .addCase(fetchSIMTax.fulfilled, (state, action) => {
                state.simTaxRes = action.payload;
            });
    },
});

export const {
    additionalLinesPurchaseReset,
    additionalLineAdded,
    additionalLineRemoved,
    additionalLinePurchaseTypeUpdated,
    additionalLineGroupUpdated,
    primaryCustomerAddressesUpdated,
    additionalLineShippingUpdated,
    planTaxReset,
    additionalLineUpdated,
    simTaxSkipped,
} = additionalLinesPurchaseSlice.actions;

export const selectAdditionalLinesPurchase = (state: RootState) => state.additionalLinesPurchase;

export const planLinePriceSelector = createSelector(
    selectAdditionalLinesPurchase,
    ({ reachPlan }) => ((100 - reachPlan!.discountPctg) * reachPlan!.baseLinePrice) / 100
);

export const totalLinesSelector = (state: RootState) =>
    state.additionalLinesPurchase.groupLines.length + state.additionalLinesPurchase.additionalCustomers.length;

export const additionalLinePriceSelector = createSelector(
    selectAdditionalLinesPurchase,
    totalLinesSelector,
    ({ reachPlan }, totalLines) => {
        let prices = 0;
        const additionalLines = totalLines - 1;
        if (reachPlan!.addLineSplitPrice) {
            for (let i = 1; i <= additionalLines; i++) {
                prices = prices + reachPlan!.addLineSplitPrice[`${i}`];
            }
        } else {
            prices = additionalLines * reachPlan!.additionalLinePrice;
        }
        return prices;
    }
);

export const planTaxSelector = (state: RootState) => state.additionalLinesPurchase.planTaxRes;
export const simTaxSelector = (state: RootState) => state.additionalLinesPurchase.simTaxRes;

export const planTaxAmountSelector = createSelector(
    planLinePriceSelector,
    additionalLinePriceSelector,
    (planLinePrice, additionalLinePrice) => planLinePrice + additionalLinePrice
);

export const monthlyBillSelector = createSelector(planTaxAmountSelector, planTaxSelector, (planTaxAmount, planTaxRes) =>
    planTaxRes ? planTaxRes.amount + planTaxRes.totalTax : planTaxAmount
);

export const welcomeKitPriceSelector = createSelector(
    configSelector,
    selectAdditionalLinesPurchase,
    (config, { additionalCustomers }) => {
        const simCostRes = config.simCostRes!;
        const simPrice = ((100 - simCostRes.discountPctg) * simCostRes.cost) / 100;
        let secondarySimPrice = simPrice;
        if (simCostRes.subsequentCost) {
            secondarySimPrice = ((100 - simCostRes.discountPctg) * simCostRes.subsequentCost) / 100;
        }

        return secondarySimPrice * additionalCustomers.length;
    }
);

export const totalDueSelector = createSelector(
    simTaxSelector,
    welcomeKitPriceSelector,
    (simTax, welcomeKitPriceSelector) => (simTax ? simTax.amount + simTax.totalTax : welcomeKitPriceSelector)
);
