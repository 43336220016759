import React, { PropsWithChildren, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setSnackbarMessage, snackbarSelector } from "../features/snackbar-slice";
import { useAppDispatch } from "../utils/store";
import tickIcon from "../images/tick-icon.png";
import Modal from "./modal";
import { Duration } from "./snackbar";

export function SuccessModalProvider({ children }: PropsWithChildren<unknown>) {
    const dispatch = useAppDispatch();
    const message = useSelector(snackbarSelector);

    return (
        <>
            {message && (
                <Modal title="" show={message != null} size="sm" onHide={() => dispatch(setSnackbarMessage(undefined))}>
                    <img src={tickIcon} className="mt-n3 mb-3" />
                    <div className="h1 text-center">{message}</div>
                </Modal>
            )}
            {children}
        </>
    );
}

export const useSuccessModal = () => {
    const dispatch = useAppDispatch();
    const message = useSelector(snackbarSelector);

    const [duration, setDuration] = useState(Duration.INDEFINITE);

    useEffect(() => {
        if (message && message.length > 0 && duration !== Duration.INDEFINITE) {
            const id = setTimeout(() => {
                dispatch(setSnackbarMessage(undefined));
            }, duration);

            return () => clearTimeout(id);
        }
    }, [message]);

    return (message?: string, duration?: Duration) => {
        if (duration) {
            setDuration(duration);
        }
        dispatch(setSnackbarMessage(message));
    };
};
