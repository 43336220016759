import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { ActivationReq } from "../utils/types";
import { Customer, CustomerStatus } from "../utils/types/customer";

interface ActivationSliceState {
    customer?: Customer;
    skipHold: boolean;
}

const initialState: ActivationSliceState = {
    skipHold: true,
};

export const checkZipcode = createReachApiThunk("activation/checkZipcode", async (zipcode: string) => {
    const res = await ReachCsAPI.checkZipcode(zipcode);
    return res;
});

export const getCustomerByEmail = createReachApiThunk("activation/getCustomerByEmail", async (emailId: string) => {
    const res = await ReachCsAPI.getCustomerByEmail(emailId);
    return res;
});

export const createCustomer = createReachApiThunk("activation/createCustomer", async (customer: Customer) => {
    const res = await ReachCsAPI.createCustomer(customer, customer.source);
    return res;
});

export const createCardHold = createReachApiThunk("activation/createCardHold", async (customerId: string) => {
    const res = await ReachCsAPI.createCardHold(customerId);
    return res;
});

export const activateLine = createReachApiThunk("activation/activateLine", async (payload: ActivationReq) => {
    const res = await ReachCsAPI.activateLine(payload);
    return res;
});

export const checkActivationIMEI = createReachApiThunk(
    "activation/checkIMEI",
    async ({ imei, reachMvne }: { imei: string; reachMvne: string }) => {
        const res = await ReachCsAPI.checkIMEI(imei, reachMvne);
        return res;
    }
);

export const validateIccId = createReachApiThunk(
    "activation/validateIccId",
    async ({ id, simId }: { id: string; simId: string }) => {
        const res = await ReachCsAPI.validateIccId(id, simId);
        return res;
    }
);

export const updateActivationCustomer = createReachApiThunk(
    "activation/updateActivationCustomer",
    async ({ id, customer }: { id: string; customer: Partial<Customer> }) => {
        const res = await ReachCsAPI.updateAccount(id, customer, process.env.MOCK_ACTIVATION ? "DATAMI" : "TELISPIRE");
        return res;
    }
);

export const activationSlice = createSlice({
    name: "activation",
    initialState,
    reducers: {
        customerSelectedForActivation(state, action: PayloadAction<Required<ActivationSliceState>>) {
            return action.payload;
        },
        activationReset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(validateIccId.fulfilled, (state, action) => {
                state.customer!.simId = action.meta.arg.simId;
            })
            .addCase(checkActivationIMEI.fulfilled, (state, action) => {
                if (action.payload.isValid) {
                    state.customer!.imei = action.meta.arg.imei;
                    state.customer!.make = action.payload.make;
                    state.customer!.model = action.payload.model;
                }
            })
            .addCase(createCustomer.fulfilled, (state, action) => {
                state.customer = action.payload;
            })
            .addCase(updateActivationCustomer.fulfilled, (state, action) => {
                state.customer = action.payload as Customer;
            })
            .addCase(activateLine.fulfilled, (state, action) => {
                if (action.meta.arg.isPort) {
                    state.customer!.status = CustomerStatus.PORTING_IN_PROGRESS;
                } else {
                    state.customer!.status = CustomerStatus.ACTIVATION_IN_PROGRESS;
                }
            });
    },
});

export const { customerSelectedForActivation } = activationSlice.actions;

export const activationCustomerSelector = (state: RootState) => state.activation.customer;

export const skipHoldSelector = (state: RootState) => state.activation.skipHold;
