import React, { DetailedHTMLProps } from "react";
import classNames from "classnames";

export interface ButtonProps
    extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    color:
        | "primary"
        | "secondary"
        | "tertiary1"
        | "tertiary2"
        | "tertiary3"
        | "tertiary4"
        | "tertiary5"
        | "error"
        | "black"
        | "grey1"
        | "grey2"
        | "grey3"
        | "grey4"
        | "white";
    fullWidth?: boolean;
    disabled?: boolean;
    loading?: boolean;
    outline?: boolean;
    icon?: string;
    mini?: boolean;
}

export function Button(props: ButtonProps) {
    const {
        color,
        fullWidth = false,
        className,
        disabled = false,
        loading = false,
        outline = false,
        icon,
        mini = false,
        children,
        ...rest
    } = props;

    return (
        <button
            {...rest}
            disabled={disabled || loading}
            className={classNames(
                "btn",
                outline ? `btn-outline-${color}` : `btn-${color}`,
                { "btn-block": fullWidth },
                { "btn-mini": mini },
                className
            )}>
            {loading ? (
                <div
                    className={classNames("spinner-border spinner-border-sm", {
                        "text-white": !outline,
                        [`text-${color}`]: outline,
                    })}
                />
            ) : (
                <>
                    {icon && (
                        <span
                            className={classNames(icon, {
                                "mr-2": !mini && children,
                            })}
                        />
                    )}
                    {!mini && children}
                </>
            )}
        </button>
    );
}
