import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DisconnectionSelection } from "../screens/leave-reach-page";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { LeaveReachReq, ReconnectionReq } from "../utils/types";
import { reset } from "./auth-slice";
import { profilesSelector } from "./profile-slice";

interface LeavingReachState {
    customerId: string;
    type: DisconnectionSelection;
    laterDisconnectionDate?: string;
}

const customerAdapter = createEntityAdapter<LeavingReachState>({
    selectId: (state) => state.customerId,
});

export const leaveReach = createReachApiThunk(
    "leavingReach/leaveReach",
    async ({ customerId, payload }: { customerId: string; payload: LeaveReachReq }) => {
        const res = await ReachCsAPI.leaveReach(customerId, payload);
        return res;
    }
);

export const updateLeaveReach = createReachApiThunk(
    "leavingReach/updateLeaveReach",
    ({ customerId, payload }: { customerId: string; payload: LeaveReachReq }) =>
        ReachCsAPI.updateLeaveReach(customerId, payload)
);

export const reconnect = createReachApiThunk("leavingReach/reconnect", async (payload: ReconnectionReq) => {
    const res = await ReachCsAPI.reconnect(payload);
    return res;
});

export const getBillingForecast = createReachApiThunk(
    "leavingReach/getBillingForecast",
    async ({ customerId, endDate }: { customerId: string; endDate: string }) => {
        const res = await ReachCsAPI.getBillingForecast(customerId, endDate);
        return res;
    }
);

export const leavingReachSlice = createSlice({
    name: "leavingReach",
    initialState: customerAdapter.getInitialState<{ reason: string }>({ reason: "" }),
    reducers: {
        customerRemoved: customerAdapter.removeOne,
        customersUpdated: customerAdapter.upsertMany,
        reasonUpdated(state, action: PayloadAction<string>) {
            state.reason = action.payload;
        },
        reset: () => customerAdapter.getInitialState({ reason: "" }),
    },
    extraReducers: (builder) => {
        builder.addCase(reset, () => customerAdapter.getInitialState({ reason: "" }));
    },
});

export const LeavingReachActions = leavingReachSlice.actions;

export const leavingReachReasonSelector = (state: RootState) => state.leavingReach.reason;

const customerSelector = customerAdapter.getSelectors<RootState>((state) => state.leavingReach);

export const selectLeavingCustomers = (customerId: string) =>
    createSelector(
        customerSelector.selectAll,
        (state) => profilesSelector.selectById(state, customerId)!.groupLines,
        (leavingCustomers, groupLines) =>
            groupLines
                .filter((customer) => leavingCustomers.some((l) => l.customerId === customer.id))
                .map((customer) => {
                    const obj = leavingCustomers.find((l) => l.customerId === customer.id)!;
                    return {
                        customer,
                        ...obj,
                    };
                })
    );

export const selectNonLeavingCustomers = (customerId: string) =>
    createSelector(
        customerSelector.selectAll,
        (state) => profilesSelector.selectById(state, customerId)!.groupLines,
        (leavingCustomers, groupLines) =>
            groupLines.filter((customer) => !leavingCustomers.some((l) => l.customerId === customer.id))
    );

export const selectPortoutCustomers = (customerId: string) =>
    createSelector(selectLeavingCustomers(customerId), (entries) =>
        entries.filter((entry) => entry.type === DisconnectionSelection.PORT_OUT)
    );

export const selectDisconnectingCustomers = (customerId: string) =>
    createSelector(selectLeavingCustomers(customerId), (entries) =>
        entries.filter((entry) => entry.type === DisconnectionSelection.DISCONNECT)
    );

export const selectDisconnectingLaterCustomers = (customerId: string) =>
    createSelector(selectLeavingCustomers(customerId), (entries) =>
        entries.filter((entry) => entry.type === DisconnectionSelection.DISCONNECT_LATER)
    );
