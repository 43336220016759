import React, { ReactNode } from "react";
import classNames from "classnames";
import Badge, { BadgeProps } from "./badge";

export interface CustomerBadgeProps extends Omit<BadgeProps, "color" | "children"> {
    className?: string;
    isPrimary?: boolean;
}

export default function CustomerBadge(props: CustomerBadgeProps) {
    const { className, isPrimary, ...rest } = props;

    return (
        <Badge
            className={classNames("badge", "font-family-medium", className)}
            color={isPrimary ? "primary" : "secondary"}
            {...rest}>
            {isPrimary ? "Primary" : "Secondary"}
        </Badge>
    );
}
