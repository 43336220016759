import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { RolesReq, RolesRes } from "../utils/types/user-roles";
import { reset } from "./auth-slice";

const rolesAdapter = createEntityAdapter<RolesRes>({
    selectId: (user) => user.id,
});

export const getRoleByRoleId = createReachApiThunk("roles/getRoleByRoleId", (roleId: string) =>
    ReachCsAPI.getRoleByRoleId(roleId)
);

export const createRole = createReachApiThunk("roles/createRole", (role: RolesReq) => ReachCsAPI.createRole(role));

export const updateRole = createReachApiThunk("roles/updateRole", (role: RolesReq) => ReachCsAPI.updateRole(role));

export const rolesSlice = createSlice({
    name: "roles",
    initialState: rolesAdapter.getInitialState(),
    reducers: {
        rolesInserted: rolesAdapter.setAll,
        rolesUpdated: rolesAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRoleByRoleId.fulfilled, (state, action) => {
                rolesAdapter.upsertOne(state, action.payload);
            })
            .addCase(createRole.fulfilled, (state, action) => {
                rolesAdapter.upsertOne(state, action.payload);
            })
            .addCase(updateRole.fulfilled, (state, action) => {
                rolesAdapter.upsertOne(state, action.payload);
            })
            .addCase(reset, () => rolesAdapter.getInitialState());
    },
});

export const { rolesUpdated, rolesInserted } = rolesSlice.actions;

export const rolesSelector = rolesAdapter.getSelectors((state: RootState) => state.roles);
