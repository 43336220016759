import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { isBrowser } from "../utils/helpers";

export enum ViewportBreakpoint {
    XS = 0,
    SM = 576,
    MD = 768,
    LG = 992,
    XL = 1200,
}

interface Viewport {
    width: number;
    height: number;
}

const ViewportContext = createContext<Viewport>({ width: 0, height: 0 });

interface ViewportProps {
    children: ReactNode;
}

export const ViewportProvider = ({ children }: ViewportProps) => {
    const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);
    const [height, setHeight] = useState(isBrowser ? window.innerHeight : 0);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>;
};

export const useViewport = (): Viewport => {
    const { width, height } = useContext(ViewportContext);
    return { width, height };
};
