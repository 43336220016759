import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import { userUpdated } from "./features/auth-slice";
import { useEffect } from "react";
import { getRolesByEmailId } from "./features/user-role-slice";
import { useAppDispatch } from "./utils/store";
import { fetchReachPlans } from "./features/plans-slice";
import {
    configSelector,
    fetchOperatorList,
    fetchPopularDestinations,
    fetchShippingRates,
    fetchSimCost,
    getProvConfig,
    getTopupPulse,
} from "./features/config-slice";
import { useSelector } from "react-redux";

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

const reachFirebaseConfig = {
    apiKey: process.env.REACH_FIREBASE_API_KEY,
    authDomain: process.env.REACH_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACH_FIREBASE_DATABASE_URL,
    projectId: process.env.REACH_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACH_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACH_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACH_FIREBASE_APP_ID,
    measurementId: process.env.REACH_FIREBASE_MEASUREMENT_ID,
};

export let reachFirebaseApp: firebase.app.App;

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    reachFirebaseApp = firebase.initializeApp(reachFirebaseConfig, "reachApp");
}

export const AuthProvider = ({ children }: any) => {
    const dispatch = useAppDispatch();
    const { operators, shippingRates } = useSelector(configSelector);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            dispatch(userUpdated({ emailId: user?.email, displayName: user?.displayName }));
            if (user) {
                const promise = Promise.all([
                    dispatch(fetchOperatorList()),
                    dispatch(fetchShippingRates()),
                    dispatch(fetchPopularDestinations()),
                    dispatch(fetchReachPlans()),
                    dispatch(fetchSimCost()),
                    dispatch(getProvConfig()),
                    dispatch(getTopupPulse()),
                ]);
                if (operators.length === 0 || shippingRates.length === 0) {
                    await promise;
                }
            }
            if (user?.email) {
                await dispatch(getRolesByEmailId(user.email));
            }
        });
    }, []);

    return children;
};
