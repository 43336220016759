import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { TicketCommentReq, TicketReq, TicketRes } from "../utils/types";

interface TicketAdapterState {
    id: string;
    tickets: TicketRes[];
}

const ticketAdapter = createEntityAdapter<TicketAdapterState>({
    selectId: (model) => model.id,
});

export const getTicketsByCustomerId = createReachApiThunk("tickets/getTicketsByCustomerId", (customerId: string) =>
    ReachCsAPI.getTicketsByCustomerId(customerId)
);

export const createTicket = createReachApiThunk("tickets/createTicket", (ticket: TicketReq) =>
    ReachCsAPI.createTicket(ticket)
);

export const getTicketComments = createReachApiThunk("tickets/getTicketComments", (ticketId: string) =>
    ReachCsAPI.getTicketComments(ticketId)
);

export const getTicketAttachments = createReachApiThunk("tickets/getTicketAttachments", (ticketId: string) =>
    ReachCsAPI.getTicketAttachments(ticketId)
);

export const updateTicketStatus = createReachApiThunk(
    "tickets/updateTicketStatus",
    ({ ticketId, status }: { ticketId: string; status: any }) => ReachCsAPI.updateTicket(ticketId, status)
);

export const createTicketComment = createReachApiThunk(
    "tickets/createTicketComment",
    ({ ticketId, payload }: { ticketId: string; payload: TicketCommentReq }) =>
        ReachCsAPI.createTicketComment(ticketId, payload)
);

export const ticketsSlice = createSlice({
    name: "tickets",
    initialState: ticketAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTicketsByCustomerId.fulfilled, (state, action) => {
            ticketAdapter.upsertOne(state, {
                id: action.meta.arg,
                tickets: action.payload,
            });
        });
    },
});

export const ticketsSelector = ticketAdapter.getSelectors<RootState>((state) => state.tickets);
