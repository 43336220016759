import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { globalErrorSelector, setSnackbarError } from "../features/global-error-slice";
import { useAppDispatch } from "../utils/store";
import Modal from "./modal";
import errorIcon from "../images/cancel-icon.png";

export function ErrorSnackbarProvider({ children }: PropsWithChildren<unknown>) {
    const dispatch = useAppDispatch();
    const error = useSelector(globalErrorSelector);

    return (
        <>
            {error && (
                <Modal title="" show={error != null} onHide={() => dispatch(setSnackbarError(undefined))}>
                    <img src={errorIcon} className="mt-n3 mb-3" />
                    <div className="font-family-semibold text-center">
                        <span className="font-family-bold">Error:</span>{" "}
                        {typeof error.message === "object"
                            ? error.message.map((message) => <div key={message}>{message}</div>)
                            : error.message}
                        <br />
                        Please contact tech team. <br />
                        {"txnId" in error && `(txnId: ${error.txnId})`}
                    </div>
                </Modal>
            )}
            {children}
        </>
    );
}

export const useErrorHandler = () => {
    const dispatch = useAppDispatch();
    return (error?: Error | { message?: string | string[] }) => {
        // window.scrollTo(0, 0);
        dispatch(setSnackbarError(error));
    };
};
