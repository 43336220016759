import React, { useEffect } from "react";
import { navigate, Link } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { useAppDispatch, useAppSelector } from "../utils/store";
import Layout from "../components/layout";
import { Card } from "../components/card";
import { profilesSelector } from "../features/profile-slice";
import branding from "../branding/branding.json";
import { Radio } from "../components/radio";
import { Button } from "../components/button";
import { useForm } from "react-hook-form";
import { formatPhone, isPostActivationState } from "../utils/helpers";
import { addMonths, format, startOfTomorrow } from "date-fns";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";
import Select from "../components/select";
import { LeavingReachActions } from "../features/leaving-reach-slice";
import CustomerBadge from "../components/customer-badge";
import { subDays } from "date-fns/esm";

export interface LeavingReachProps extends RouteComponentProps {
    customerId: string;
}

export enum DisconnectionSelection {
    PORT_OUT = "PORT_OUT",
    DISCONNECT = "DISCONNECT",
    DISCONNECT_LATER = "DISCONNECT_LATER",
    CANCEL = "CANCEL",
}

type CustomerPortInfo = {
    type: DisconnectionSelection | "";
    laterDisconnectionDate?: Date;
};

type FormData = Record<string, CustomerPortInfo | DisconnectionSelection | "">;

function isCustomerPortInfo(input: FormData[keyof FormData]): input is CustomerPortInfo {
    return (
        (input as CustomerPortInfo)?.type !== undefined &&
        (input as CustomerPortInfo).type !== "" &&
        (input as CustomerPortInfo)?.type !== null
    );
}

export default function LeavingReach(props: LeavingReachProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId));

    const { register, handleSubmit, setValue, reset, watch, errors } = useForm<FormData>({
        mode: "onBlur",
    });

    const { width } = useViewport();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(LeavingReachActions.reset());
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    const customerList = customerProfile.groupLines.filter((customer) => isPostActivationState(customer.status));

    const watchDates = watch(customerList.map((customer) => `${customer.id}.laterDisconnectionDate`));

    const primaryCustomer = customerProfile.groupLines.find((customer) => customer.isPrimary)!;

    const primaryCustomerSelection = watch(primaryCustomer.id);

    const onSubmit = (data: FormData) => {
        console.log(data);

        const isValidSelection = Object.entries(data).some(([_, value]) => isCustomerPortInfo(value));

        const entries = Object.entries(data)
            .filter(([_, value]) => isCustomerPortInfo(value))
            .map(([key, value]) => ({
                customerId: key,
                type: (value as CustomerPortInfo).type as DisconnectionSelection,
                laterDisconnectionDate: (value as CustomerPortInfo).laterDisconnectionDate?.toISOString(),
            }));

        if (isValidSelection) {
            dispatch(LeavingReachActions.customersUpdated(entries));
            navigate(`/disconnections-summary/${customerId}`);
        } else {
            console.log("No option selected");
        }
    };

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Disconnections`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <h2>Disconnections</h2>
                <Card>
                    <form className="d-flex flex-column w-100" onSubmit={handleSubmit(onSubmit)}>
                        {width >= ViewportBreakpoint.MD ? (
                            <table className="table table-sm font-family-semibold mb-0">
                                <thead className="divider">
                                    <tr className="font-family-bold">
                                        <th></th>
                                        {customerProfile.reachPlan.maxLines > 1 ? (
                                            <>
                                                <th>
                                                    <Radio
                                                        inline
                                                        small
                                                        id="portout"
                                                        defaultChecked={false}
                                                        label="All"
                                                        register={register}
                                                        value={DisconnectionSelection.PORT_OUT}
                                                        name="allSelection"
                                                        onChange={({ target: { checked } }) => {
                                                            if (checked) {
                                                                for (const customer of customerList) {
                                                                    setValue(
                                                                        `${customer.id}.type`,
                                                                        DisconnectionSelection.PORT_OUT
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </th>
                                                <th>
                                                    <Radio
                                                        inline
                                                        small
                                                        id="disconnect"
                                                        defaultChecked={false}
                                                        label="All"
                                                        register={register}
                                                        value={DisconnectionSelection.DISCONNECT}
                                                        name="allSelection"
                                                        onChange={({ target: { checked } }) => {
                                                            if (checked) {
                                                                for (const customer of customerList) {
                                                                    setValue(
                                                                        `${customer.id}.type`,
                                                                        DisconnectionSelection.DISCONNECT
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </th>
                                                <th>
                                                    <Radio
                                                        inline
                                                        small
                                                        id="disconnectLater"
                                                        defaultChecked={false}
                                                        label="All"
                                                        register={register}
                                                        value={DisconnectionSelection.DISCONNECT_LATER}
                                                        name="allSelection"
                                                        disabled={
                                                            isCustomerPortInfo(primaryCustomerSelection) &&
                                                            (primaryCustomerSelection.type ===
                                                                DisconnectionSelection.PORT_OUT ||
                                                                primaryCustomerSelection.type ===
                                                                    DisconnectionSelection.DISCONNECT)
                                                        }
                                                        onChange={({ target: { checked } }) => {
                                                            if (checked) {
                                                                for (const customer of customerList) {
                                                                    setValue(
                                                                        `${customer.id}.type`,
                                                                        DisconnectionSelection.DISCONNECT_LATER
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </th>
                                            </>
                                        ) : (
                                            <>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </>
                                        )}
                                        <th
                                            className="text-cta text-right"
                                            onClick={() => {
                                                reset({});
                                            }}>
                                            Clear all
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customerList.map((customer) => {
                                        const selectedDate = watchDates[`${customer.id}.laterDisconnectionDate`];
                                        const watchType = watch(`${customer.id}.type`);

                                        return (
                                            <tr key={customer.id}>
                                                <td className="py-2">
                                                    <div>
                                                        {customer.firstName} {customer.lastName}
                                                    </div>
                                                    <div className="font-family-medium">
                                                        {formatPhone(customer.reachNumber)}
                                                    </div>
                                                    <div>
                                                        <CustomerBadge isPrimary={customer.isPrimary} />
                                                    </div>
                                                </td>
                                                <td className="py-2">
                                                    <Radio
                                                        label="Portout"
                                                        name={`${customer.id}.type`}
                                                        small
                                                        inline
                                                        defaultChecked={false}
                                                        register={register}
                                                        onChange={({ target: { checked } }) => {
                                                            if (checked) {
                                                                setValue("allSelection", undefined);
                                                            }
                                                        }}
                                                        value={DisconnectionSelection.PORT_OUT}
                                                        id={`${customer.id}:portout`}
                                                    />
                                                </td>
                                                <td className="py-2">
                                                    <Radio
                                                        label="Disconnect now"
                                                        name={`${customer.id}.type`}
                                                        small
                                                        inline
                                                        defaultChecked={false}
                                                        register={register}
                                                        onChange={({ target: { checked } }) => {
                                                            if (checked) {
                                                                setValue("allSelection", undefined);
                                                            }
                                                        }}
                                                        value={DisconnectionSelection.DISCONNECT}
                                                        id={`${customer.id}:disconnect`}
                                                    />
                                                </td>
                                                <td className="py-2">
                                                    <div>
                                                        <Radio
                                                            label="Disconnect later"
                                                            name={`${customer.id}.type`}
                                                            small
                                                            inline
                                                            defaultChecked={false}
                                                            register={register}
                                                            disabled={
                                                                !customer.isPrimary &&
                                                                isCustomerPortInfo(primaryCustomerSelection) &&
                                                                (primaryCustomerSelection.type ===
                                                                    DisconnectionSelection.PORT_OUT ||
                                                                    primaryCustomerSelection.type ===
                                                                        DisconnectionSelection.DISCONNECT)
                                                            }
                                                            onChange={({ target: { checked } }) => {
                                                                if (checked) {
                                                                    setValue("allSelection", undefined);
                                                                }
                                                            }}
                                                            value={DisconnectionSelection.DISCONNECT_LATER}
                                                            id={`${customer.id}:disconnectLater`}
                                                        />
                                                    </div>
                                                    {watchType === DisconnectionSelection.DISCONNECT_LATER && (
                                                        <div
                                                            className="position-relative mt-1 cursor-pointer"
                                                            style={{ height: 16 }}>
                                                            <span className="datepicker-toggle-button">
                                                                <span className="datepicker-toggle reach-cs-calendar text-secondary mr-2" />
                                                                <input
                                                                    type="date"
                                                                    name={`${customer.id}.laterDisconnectionDate`}
                                                                    min={format(startOfTomorrow(), "yyyy-MM-dd")}
                                                                    max={
                                                                        customer.isPrimary
                                                                            ? format(
                                                                                  addMonths(
                                                                                      subDays(
                                                                                          new Date(
                                                                                              customer.nextBillingDate ||
                                                                                                  ""
                                                                                          ),
                                                                                          1
                                                                                      ),
                                                                                      1
                                                                                  ),
                                                                                  "yyyy-MM-dd"
                                                                              )
                                                                            : isCustomerPortInfo(
                                                                                  primaryCustomerSelection
                                                                              ) &&
                                                                              primaryCustomerSelection.laterDisconnectionDate !=
                                                                                  null
                                                                            ? format(
                                                                                  primaryCustomerSelection.laterDisconnectionDate,
                                                                                  "yyyy-MM-dd"
                                                                              )
                                                                            : undefined
                                                                    }
                                                                    className="datepicker-input"
                                                                    ref={register({
                                                                        required: "Date is required",
                                                                        valueAsDate: true,
                                                                    })}
                                                                />
                                                                <span
                                                                    className="text-secondary font-family-medium"
                                                                    style={{ fontSize: 12 }}>
                                                                    {selectedDate && selectedDate instanceof Date
                                                                        ? format(selectedDate, "MM/dd/yyyy")
                                                                        : "Select date"}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    )}
                                                </td>
                                                <td
                                                    className="py-2 text-cta text-right"
                                                    onClick={() => {
                                                        setValue(`${customer.id}.type`, undefined);
                                                        setValue(`${customer.id}.laterDisconnectionDate`, undefined);
                                                        setValue("allSelection", undefined);
                                                    }}>
                                                    Clear
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="font-family-semibold">
                                <div
                                    className="text-cta text-right"
                                    onClick={() => {
                                        reset({});
                                    }}>
                                    Clear all
                                </div>
                                {customerProfile.reachPlan.maxLines > 1 && (
                                    <>
                                        <Radio
                                            small
                                            id="portout"
                                            defaultChecked={false}
                                            label="Port-out all"
                                            register={register}
                                            className="mt-2"
                                            value={DisconnectionSelection.PORT_OUT}
                                            name="allSelection"
                                            onChange={({ target: { checked } }) => {
                                                if (checked) {
                                                    for (const customer of customerList) {
                                                        setValue(
                                                            `${customer.id}.type`,
                                                            DisconnectionSelection.PORT_OUT
                                                        );
                                                    }
                                                }
                                            }}
                                        />
                                        <Radio
                                            small
                                            id="disconnect"
                                            defaultChecked={false}
                                            label="Disconnect all"
                                            register={register}
                                            className="mt-2"
                                            value={DisconnectionSelection.DISCONNECT}
                                            name="allSelection"
                                            onChange={({ target: { checked } }) => {
                                                if (checked) {
                                                    for (const customer of customerList) {
                                                        setValue(
                                                            `${customer.id}.type`,
                                                            DisconnectionSelection.DISCONNECT
                                                        );
                                                    }
                                                }
                                            }}
                                        />
                                        <Radio
                                            small
                                            id="disconnectLater"
                                            defaultChecked={false}
                                            label="Disconnect all later"
                                            register={register}
                                            className="mt-2"
                                            value={DisconnectionSelection.DISCONNECT_LATER}
                                            name="allSelection"
                                            disabled={
                                                isCustomerPortInfo(primaryCustomerSelection) &&
                                                (primaryCustomerSelection.type === DisconnectionSelection.PORT_OUT ||
                                                    primaryCustomerSelection.type === DisconnectionSelection.DISCONNECT)
                                            }
                                            onChange={({ target: { checked } }) => {
                                                if (checked) {
                                                    for (const customer of customerList) {
                                                        setValue(
                                                            `${customer.id}.type`,
                                                            DisconnectionSelection.DISCONNECT_LATER
                                                        );
                                                    }
                                                }
                                            }}
                                        />
                                    </>
                                )}
                                <div className="divider my-3" />

                                {customerList.map((customer) => {
                                    const selectedDate = watchDates[`${customer.id}.laterDisconnectionDate`];
                                    const watchType = watch(`${customer.id}.type`);

                                    return (
                                        <div key={customer.id} className="py-3 divider">
                                            <div>
                                                {customer.firstName} {customer.lastName}
                                            </div>
                                            <div className="font-family-medium">
                                                {formatPhone(customer.reachNumber)}
                                            </div>
                                            <div>
                                                <CustomerBadge isPrimary={customer.isPrimary} />
                                            </div>
                                            <Select
                                                name={`${customer.id}.type`}
                                                register={register}
                                                className="mt-2"
                                                onChange={() => {
                                                    setValue("allSelection", undefined);
                                                }}>
                                                <option value="">Select action</option>
                                                <option value={DisconnectionSelection.PORT_OUT}>Port out</option>
                                                <option value={DisconnectionSelection.DISCONNECT}>
                                                    Disconnect now
                                                </option>
                                                <option
                                                    value={DisconnectionSelection.DISCONNECT_LATER}
                                                    disabled={
                                                        !customer.isPrimary &&
                                                        isCustomerPortInfo(primaryCustomerSelection) &&
                                                        (primaryCustomerSelection.type ===
                                                            DisconnectionSelection.PORT_OUT ||
                                                            primaryCustomerSelection.type ===
                                                                DisconnectionSelection.DISCONNECT)
                                                    }>
                                                    Disconnect later
                                                </option>
                                            </Select>
                                            {watchType === DisconnectionSelection.DISCONNECT_LATER && (
                                                <div
                                                    className="position-relative mt-1 cursor-pointer"
                                                    style={{ height: 16 }}>
                                                    <span className="datepicker-toggle-button">
                                                        <span className="datepicker-toggle reach-cs-calendar text-secondary mr-2" />
                                                        <input
                                                            type="date"
                                                            name={`${customer.id}.laterDisconnectionDate`}
                                                            min={format(startOfTomorrow(), "yyyy-MM-dd")}
                                                            max={
                                                                customer.isPrimary
                                                                    ? format(
                                                                          addMonths(
                                                                              subDays(
                                                                                  new Date(
                                                                                      customer.nextBillingDate || ""
                                                                                  ),
                                                                                  1
                                                                              ),
                                                                              1
                                                                          ),
                                                                          "yyyy-MM-dd"
                                                                      )
                                                                    : isCustomerPortInfo(primaryCustomerSelection) &&
                                                                      primaryCustomerSelection.laterDisconnectionDate !=
                                                                          null
                                                                    ? format(
                                                                          primaryCustomerSelection.laterDisconnectionDate,
                                                                          "yyyy-MM-dd"
                                                                      )
                                                                    : undefined
                                                            }
                                                            className="datepicker-input"
                                                            ref={register({
                                                                required: "Date is required",
                                                                valueAsDate: true,
                                                            })}
                                                        />
                                                        <span
                                                            className="text-secondary font-family-medium"
                                                            style={{ fontSize: 12 }}>
                                                            {selectedDate && selectedDate instanceof Date
                                                                ? format(selectedDate, "MM/dd/yyyy")
                                                                : "Select date"}
                                                        </span>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {customerList.map((customer) => {
                            // @ts-ignore
                            const error = errors[customer.id]?.laterDisconnectionDate;
                            if (!error) {
                                return null;
                            }

                            return (
                                <div key={customer.id} className="text-error">
                                    {error.message}
                                </div>
                            );
                        })}

                        <Button color="secondary" className="mt-3 align-self-center col-12 col-md-4">
                            Next
                        </Button>
                    </form>
                </Card>
            </div>
        </Layout>
    );
}
