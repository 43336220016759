import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { compareDesc } from "date-fns";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { Bill } from "../utils/types/bill";

interface BillingAdapterState {
    id: string;
    bills: Bill[];
}

const billingAdapter = createEntityAdapter<BillingAdapterState>({
    selectId: (model) => model.id,
});

export const waiveOffLatePayment = createReachApiThunk("billing/waiveOffLatePayment", async (customerId: string) => {
    const res = ReachCsAPI.waiveOffLatePayment(customerId);
    return res;
});

export const regenerateBill = createReachApiThunk("billing/regenerateBill", async (customerId: string) => {
    const res = ReachCsAPI.regenerateBill(customerId);
    return res;
});

export const payBill = createReachApiThunk(
    "billing/payBill",
    async ({ customerId, ccUUID, billId }: { customerId: string; ccUUID: string; billId: number }) => {
        const res = ReachCsAPI.payBill(customerId, ccUUID, billId);
        return res;
    }
);

export const getBillingHistory = createReachApiThunk("billing/getBillingHistory", async (customerId: string) => {
    const res = await ReachCsAPI.getBillingHistory(customerId);
    return res;
});

export const setPromiseToPayDate = createReachApiThunk(
    "billing/setPromiseToPayDate",
    async ({ customerId, date }: { customerId: string; date: string }) =>
        ReachCsAPI.setPromiseToPayDate(customerId, date)
);

export const billingSlice = createSlice({
    name: "billing",
    initialState: billingAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBillingHistory.fulfilled, (state, action) => {
            const id = action.meta.arg;
            const bills = action.payload.sort((a, b) => compareDesc(new Date(a.billEndDate), new Date(b.billEndDate)));
            billingAdapter.upsertOne(state, {
                id,
                bills,
            });
        });
    },
});

export const billsSelector = billingAdapter.getSelectors<RootState>((state) => state.billing);

export const paidBillsSelector = (id: string) =>
    createSelector(
        (state: RootState) => billsSelector.selectById(state, id),
        (res) =>
            res?.bills
                .filter((bill) => !!bill.billPaidDate)
                .sort((a, b) => compareDesc(new Date(a.billPaidDate), new Date(b.billPaidDate)))
    );

export const currentBillSelector = (id: string) =>
    createSelector(
        (state: RootState) => billsSelector.selectById(state, id),
        (res) => res?.bills[0]
    );
