import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { InternationalCountriesRes } from "../utils/types";

export const getInternationalCountryList = createReachApiThunk(
    "intlCountries/getInternationalCountryList",
    async (mvne: string) => {
        const res = await ReachCsAPI.getInternationalCountryList(mvne);
        return res;
    }
);

interface IntlCountriesAdapterState {
    source: string;
    countryRes: InternationalCountriesRes;
}

const intlCountriesAdapter = createEntityAdapter<IntlCountriesAdapterState>({
    selectId: (model) => model.source,
});

export const intlCountriesSlice = createSlice({
    name: "intlCountries",
    initialState: intlCountriesAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInternationalCountryList.fulfilled, (state, action) => {
            const source = action.meta.arg;
            intlCountriesAdapter.upsertOne(state, {
                source,
                countryRes: action.payload,
            });
        });
    },
});

export const intlCountriesSelector = intlCountriesAdapter.getSelectors<RootState>((state) => state.intlCountries);
