import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { compareDesc } from "date-fns";
import { TripFilter } from "../screens/intl-roaming/manage-ir-page";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { IRTrip, TripStatus } from "../utils/types";
import { Customer, CustomerStatus } from "../utils/types/customer";
import { reset } from "./auth-slice";

export const getIntlTrips = createReachApiThunk("intlRoaming/getIntlTrips", async (groupId: string) =>
    ReachCsAPI.getIntlTrips(groupId)
);

export const getIntlOverallDataUsage = createReachApiThunk(
    "intlRoaming/getIntlOverallDataUsage",
    async (tripId: string) => ReachCsAPI.getIntlOverallDataUsage(tripId)
);

export const getIntlAvailableCredits = createReachApiThunk(
    "intlRoaming/getIntlAvailableCredits",
    async (customerId: string) => ReachCsAPI.getIntlAvailableCredits(customerId)
);

export const updateTrip = createReachApiThunk(
    "intlRoaming/updateTrip",
    async ({ tripId, trip }: { tripId: string; trip: IRTrip }) => ReachCsAPI.updateTrip(tripId, trip)
);

export const deactivateTrip = createReachApiThunk("irPurchase/deactivateTrip", async (tripId: string) =>
    ReachCsAPI.deactivateTrip(tripId)
);

interface IRTripAdapterState {
    groupId: string;
    trips: IRTrip[];
}

const roamingAdapter = createEntityAdapter<IRTripAdapterState>({
    selectId: (model) => model.groupId,
});

export const intlRoamingSlice = createSlice({
    name: "intlRoaming",
    initialState: roamingAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getIntlTrips.fulfilled, (state, action) => {
                const id = action.meta.arg;
                roamingAdapter.upsertOne(state, {
                    groupId: id,
                    trips: action.payload,
                });
            })
            .addCase(getIntlTrips.rejected, (state, action) => {
                const id = action.meta.arg;
                if ((action.payload as any).resCode === 40193) {
                    roamingAdapter.upsertOne(state, {
                        groupId: id,
                        trips: [],
                    });
                }
            })
            .addCase(reset, () => roamingAdapter.getInitialState());
    },
});

export const irTripSelector = roamingAdapter.getSelectors<RootState>((state) => state.intlRoaming);

export const selectOngoingTrips = (groupId: string) =>
    createSelector(
        (state: RootState) => irTripSelector.selectById(state, groupId),
        (res) => {
            if (!res) {
                return undefined;
            }
            return res.trips
                .filter(
                    (trip) =>
                        trip.status === TripStatus.ACTIVATED ||
                        trip.status === TripStatus.DEACTIVATED ||
                        trip.status === TripStatus.ACTIVATION_FAILED
                )
                .sort((a, b) => {
                    if (a.status === TripStatus.DEACTIVATED) {
                        return -1;
                    }

                    return compareDesc(new Date(a.startDate!), new Date(b.startDate!));
                });
        }
    );

export const selectUpcomingTrips = (groupId: string) =>
    createSelector(
        (state: RootState) => irTripSelector.selectById(state, groupId),
        (res) => {
            if (!res) {
                return undefined;
            }
            return res.trips
                .filter((trip) => trip.status === TripStatus.INACTIVE)
                .sort((a, b) => compareDesc(new Date(a.tentativeStartDate!), new Date(b.tentativeStartDate!)));
        }
    );

export const selectPastTrips = (groupId: string) =>
    createSelector(
        (state: RootState) => irTripSelector.selectById(state, groupId),
        (res) => {
            if (!res) {
                return undefined;
            }
            return res.trips
                .filter((trip) => trip.status === TripStatus.COMPLETED)
                .sort((a, b) => compareDesc(new Date(a.startDate!), new Date(b.startDate!)));
        }
    );

export const selectTrips = (groupId: string, filter: TripFilter) =>
    createSelector(
        selectOngoingTrips(groupId),
        selectUpcomingTrips(groupId),
        selectPastTrips(groupId),
        (ongoingTrips, upcomingTrips, pastTrips) => {
            if (filter === TripFilter.Current) {
                return ongoingTrips;
            } else if (filter === TripFilter.Upcoming) {
                return upcomingTrips;
            }
            return pastTrips;
        }
    );

export const selectAllowedCustomers = (customers: Customer[], groupId: string) =>
    createSelector(selectOngoingTrips(groupId), selectUpcomingTrips(groupId), (ongoingTrips, upcomingTrips) => {
        return customers.filter(
            (customer) =>
                customer.status === CustomerStatus.ACTIVE &&
                !ongoingTrips?.find((trip) => trip.customerId === customer.id) &&
                !upcomingTrips?.find((trip) => trip.customerId === customer.id)
        );
    });

export const selectTripById = (groupId: string, tripId: string) =>
    createSelector(
        (state: RootState) => irTripSelector.selectById(state, groupId),
        (res) => res?.trips.find((trip) => trip.id === tripId)
    );
