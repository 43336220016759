import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { reachFirebaseApp } from "../firebase";
import { ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { SimCostRes, TopupPulseRes } from "../utils/types";
import { ShippingMethod } from "../utils/types/customer";
import { isFirebaseDBAllowed } from "../utils/helpers";

interface Operator {
    displayName: string;
    value: string;
}

interface ConfigSliceState {
    operators: Operator[];
    shippingRates: ShippingMethod[];
    simCostRes?: SimCostRes;
    intlPopularDestinations: string[];
    provConfig: {
        AUTO_TOP_UP_SIZE: number;
        AUTO_TOP_UP_AMOUNT: number;
        DATA_PULSE: number;
        MAX_NO_OF_PULSES: number;
        ILD_MAX_CREDIT_AMOUNT: number;
        ILD_MIN_CREDIT_AMOUNT: number;
        ILD_AUTO_CHARGE_AMOUNT: number;
        ILD_PULSE_UNIT: string;
        ILD_PULSE_SIZE: number;
        ILD_SERVICE_CHRAGE: number; // DO NOT RENAME: spelling mistake in backend
        ILD_INCR_CREDIT_AMOUNT: number;
        ILD_THRESHOLD_AMOUNT: number;
        RECONNECTION_FEE: number;
        IR_MIN_CREDIT_AMOUNT: number;
        IR_MAX_CREDIT_AMOUNT: number;
        IR_INCR_CREDIT_AMOUNT: number;
        IR_MIN_COVER_CREDIT_AMOUNT: number;
        LATEST_APP_VERSION: string;
    };
    topupPulse: TopupPulseRes;
}

const initialState: ConfigSliceState = {
    operators: [],
    shippingRates: [],
    intlPopularDestinations: [],
    provConfig: {
        AUTO_TOP_UP_SIZE: 0,
        AUTO_TOP_UP_AMOUNT: 0,
        DATA_PULSE: 0,
        MAX_NO_OF_PULSES: 0,
        ILD_MAX_CREDIT_AMOUNT: 0,
        ILD_MIN_CREDIT_AMOUNT: 0,
        ILD_AUTO_CHARGE_AMOUNT: 0,
        ILD_PULSE_UNIT: "Min",
        ILD_PULSE_SIZE: 0,
        ILD_SERVICE_CHRAGE: 0,
        ILD_INCR_CREDIT_AMOUNT: 0,
        ILD_THRESHOLD_AMOUNT: 0,
        RECONNECTION_FEE: 0,
        IR_MIN_CREDIT_AMOUNT: 0,
        IR_MAX_CREDIT_AMOUNT: 0,
        IR_INCR_CREDIT_AMOUNT: 0,
        IR_MIN_COVER_CREDIT_AMOUNT: 0,
        LATEST_APP_VERSION: "",
    },
    topupPulse: {
        pulse: 0,
        pulseCost: 0,
        limit: 0,
        recipientPulseCost: 0,
    },
};

const configKeys = Object.keys(initialState.provConfig);

export const getTopupPulse = createAsyncThunk("config/getTopupPulse", async () => {
    const res = await ReachCsAPI.getTopupPulse();
    return res;
});

export const fetchOperatorList = createAsyncThunk("config/fetchOperatorList", async () => {
    if (!isFirebaseDBAllowed()) {
        const configData = await ReachCsAPI.getAppData();
        return configData?.operators;
    } else {
        const snapshot = await reachFirebaseApp.database().ref("operators").once("value");
        return snapshot.val() as Operator[];
    }
});

export const fetchShippingRates = createAsyncThunk("config/fetchShippingRates", async () => {
    if (!isFirebaseDBAllowed()) {
        const configData = await ReachCsAPI.getAppData();
        return configData?.shippingRates;
    } else {
        const snapshot = await reachFirebaseApp.database().ref("shippingRates").once("value");
        return snapshot.val() as ShippingMethod[];
    }
});

export const fetchPopularDestinations = createAsyncThunk("config/fetchPopularDestinations", async () => {
    if (!isFirebaseDBAllowed()) {
        return [];
    } else {
        const snapshot = await reachFirebaseApp.database().ref("intlPopularDestinations").once("value");
        return snapshot.val() as string[];
    }
});

export const fetchSimCost = createAsyncThunk("config/fetchSimCost", async () => {
    if (!isFirebaseDBAllowed()) {
        const configData = await ReachCsAPI.getAppData();
        return configData?.perSimCost?.["USA"];
    } else {
        const snapshot = await reachFirebaseApp.database().ref("perSimCost/USA").once("value");
        return snapshot.val() as SimCostRes;
    }
});

export const getProvConfig = createAsyncThunk("config/getProvConfig", async () => {
    const res = await ReachCsAPI.getProvConfig(configKeys.join(","));
    return res;
});

export const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOperatorList.fulfilled, (state, action) => {
                state.operators = action.payload;
                state.operators.push({
                    displayName: "Other",
                    value: "Other",
                });
            })
            .addCase(fetchShippingRates.fulfilled, (state, action) => {
                state.shippingRates = action.payload;
            })
            .addCase(fetchSimCost.fulfilled, (state, action) => {
                state.simCostRes = action.payload;
            })
            .addCase(fetchPopularDestinations.fulfilled, (state, action) => {
                state.intlPopularDestinations = action.payload;
            })
            .addCase(getProvConfig.fulfilled, (state, action) => {
                const config = action.payload;
                state.provConfig = {
                    AUTO_TOP_UP_SIZE: parseFloat(config.AUTO_TOP_UP_SIZE),
                    AUTO_TOP_UP_AMOUNT: parseFloat(config.AUTO_TOP_UP_AMOUNT),
                    DATA_PULSE: parseFloat(config.DATA_PULSE),
                    MAX_NO_OF_PULSES: parseFloat(config.MAX_NO_OF_PULSES),
                    ILD_MAX_CREDIT_AMOUNT: parseFloat(config.ILD_MAX_CREDIT_AMOUNT),
                    ILD_MIN_CREDIT_AMOUNT: parseFloat(config.ILD_MIN_CREDIT_AMOUNT),
                    ILD_AUTO_CHARGE_AMOUNT: parseFloat(config.ILD_AUTO_CHARGE_AMOUNT),
                    ILD_PULSE_UNIT: config.ILD_PULSE_UNIT,
                    ILD_PULSE_SIZE: parseFloat(config.ILD_PULSE_SIZE),
                    ILD_SERVICE_CHRAGE: parseFloat(config.ILD_SERVICE_CHRAGE),
                    ILD_INCR_CREDIT_AMOUNT: parseFloat(config.ILD_INCR_CREDIT_AMOUNT),
                    ILD_THRESHOLD_AMOUNT: parseFloat(config.ILD_THRESHOLD_AMOUNT),
                    RECONNECTION_FEE: parseFloat(config.RECONNECTION_FEE),
                    IR_MIN_CREDIT_AMOUNT: parseFloat(config.IR_MIN_CREDIT_AMOUNT),
                    IR_MAX_CREDIT_AMOUNT: parseFloat(config.IR_MAX_CREDIT_AMOUNT),
                    IR_INCR_CREDIT_AMOUNT: parseFloat(config.IR_INCR_CREDIT_AMOUNT),
                    IR_MIN_COVER_CREDIT_AMOUNT: parseFloat(config.IR_MIN_COVER_CREDIT_AMOUNT),
                    LATEST_APP_VERSION: config.LATEST_APP_VERSION,
                };
            })
            .addCase(getTopupPulse.fulfilled, (state, action) => {
                state.topupPulse = action.payload;
            });
    },
});

export const configSelector = (state: RootState) => state.config;

export const provConfig = (state: RootState) => state.config.provConfig;

export const topupPulseSelector = (state: RootState) => state.config.topupPulse;
