import { addMinutes, endOfMonth, format } from "date-fns";
import { CustomerStatus } from "./types/customer";

export const isBrowser = typeof window !== `undefined`;

export function formatPhone(number: string): string {
    return number?.replace(/^(\d{3})(\d{1,3})(\d{0,4})$/, (match, p1, p2, p3) => {
        let result = "";
        if (!p1) {
            return result;
        }

        if (!p2) {
            return p1;
        }

        result = `(${p1})`;

        if (p2) {
            result += ` ${p2}`;
        }

        if (p3) {
            result += `-${p3}`;
        }

        return result;
    });
}

export function parseExpiry(dateString: string): Date {
    let date = new Date(parseInt("20" + dateString.substring(2), 10), parseInt(dateString.substring(0, 2), 10) - 1, 1);
    date = endOfMonth(date);
    return date;
}

export function getNumberWithOrdinal(n: number): string {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const NAME_PATTERN = /^[A-Za-z -]{2,24}$/;
export const EMAIL_PATTERN =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export function isPreActivationState(state: CustomerStatus) {
    return (
        state === CustomerStatus.INACTIVE ||
        state === CustomerStatus.ACTIVATION_IN_PROGRESS ||
        state === CustomerStatus.REJECTED ||
        state === CustomerStatus.ACTIVATION_FAILED ||
        state === CustomerStatus.PORTING_FAILED ||
        state === CustomerStatus.PORTING_IN_PROGRESS ||
        state === CustomerStatus.PORT_CANCELLED
    );
}

export function isPostActivationState(status: CustomerStatus) {
    return (
        status === CustomerStatus.ACTIVE ||
        status === CustomerStatus.SUSPENDED ||
        status === CustomerStatus.DISABLED ||
        status === CustomerStatus.HOTLINED
    );
}

export function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function getUTCDate(date: Date) {
    return addMinutes(date, date.getTimezoneOffset());
}

export const PaymentFailureMessage: Record<string, string> = {
    "201": "You have entered an invalid Credit Card number. Please retry with the correct card details.",
    "302": "Payment failed because of insufficient funds. Please retry with another card.",
    "303": "Payment was declined by the issuing bank. Please get in touch with your bank to resolve the issue, or try another card.",
    "531": "You have entered an incorrect CVV2 number. Please retry with the correct one.",
    "502": "Payment was declined by the issuing bank. Please get in touch with your bank to resolve the issue, or try another card.",
    "522": "You entered an expiry date that has already passed. Please retry with the correct card details.",
};

export function getPaymentFailureMessageForCode(code: string): string {
    return (
        PaymentFailureMessage[code] ??
        "We were unable to process your payment. Please review your payment details and try again."
    );
}

export const isSSOEnabled = (): boolean => {
    return process.env.SSO === "true";
};
export const isReachSSOEnabled = (): boolean => {
    return process.env.SSO_REACH === "true";
};

export const isFirebaseDBAllowed = (): boolean => {
    return !isReachSSOEnabled();
};
