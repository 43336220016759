import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { Coupon, TaxRes, TaxType, CouponType, SearchableCustomer } from "../utils/types";
import { Address, Customer } from "../utils/types/customer";
import { reset } from "./auth-slice";
import { configSelector } from "./config-slice";
import { plansSelector } from "./plans-slice";

interface PurchaseSliceState {
    customer: Partial<Customer>;
    planTaxRes?: TaxRes;
    simTaxRes?: TaxRes;
    totalLines: number;
    appliedCoupon?: Coupon;
    isSimInHand: boolean;
}

const initialState: PurchaseSliceState = {
    customer: {},
    totalLines: 1,
    isSimInHand: false,
};

export const updateHubspot = createReachApiThunk(
    "purchase/updateHubspot",
    async (payload: { email: string } & Record<string, string | undefined>) => {
        const res = await ReachCsAPI.updateHubspot(payload);
        return res;
    }
);

export const sendPaymentAndPasswordLinks = createReachApiThunk(
    "purchase/sendPaymentAndPasswordLinks",
    async (customerId: string) => {
        const res = await ReachCsAPI.sendPaymentLink(customerId);
        return res;
    }
);

export const sendCardAndPasswordLinks = createReachApiThunk(
    "purchase/sendCardAndPasswordLinks",
    async (customerId: string) => {
        const res = await ReachCsAPI.addCreditCard(customerId, true);
        return res;
    }
);

export const checkZipCoverage = createReachApiThunk("purchase/checkZipCoverage", async (zipcode: string) => {
    const res = await ReachCsAPI.checkZipcode(zipcode);
    return res;
});

export const checkIMEI = createReachApiThunk(
    "purchase/checkIMEI",
    async ({ imei, reachMvne }: { imei: string; reachMvne: string }) => {
        const res = await ReachCsAPI.checkIMEI(imei, reachMvne);
        return res;
    }
);

export const submitMNPValidate = createReachApiThunk("purchase/submitMNPValidate", async (phoneNumber: string) => {
    const res = await ReachCsAPI.submitMNPValidate(
        phoneNumber,
        true,
        process.env.MOCK_ACTIVATION ? "DATAMI" : "TELISPIRE"
    );
    return res;
});

export const validateMNP = createReachApiThunk("purchase/validateMNP", async (refNumber: string) => {
    const res = await ReachCsAPI.validateMNP(refNumber);
    return res;
});

export const getCoupon = createReachApiThunk("purchase/getCoupon", async (couponCode: string) => {
    const res = await ReachCsAPI.getCoupon(couponCode);
    return res;
});

export const getCouponEligibility = createReachApiThunk("purchase/getCouponEligibility", async (emailId: string) => {
    const res = await ReachCsAPI.getCouponEligibility(emailId);
    return res;
});

export const createAccount = createReachApiThunk("purchase/createAccount", async (customer: Partial<Customer>) => {
    const res = await ReachCsAPI.createAccount(customer, process.env.MOCK_ACTIVATION ? "DATAMI" : "TELISPIRE");
    return res;
});

export const fetchAccount = createReachApiThunk("purchase/fetchAccount", async (id: string) => {
    const res = await ReachCsAPI.fetchProfile(id);
    return res;
});

export const updateAccount = createReachApiThunk(
    "purchase/updateAccount",
    async ({ id, customer }: { id: string; customer: Partial<Customer> }) => {
        const res = await ReachCsAPI.updateAccount(id, customer, process.env.MOCK_ACTIVATION ? "DATAMI" : "TELISPIRE");
        return res;
    }
);

export const fetchPlanTax = createReachApiThunk(
    "purchase/fetchPlanTax",
    async ({ amount, address, additionalLines }: { amount: number; address: Address; additionalLines: number }) => {
        const res = await ReachCsAPI.getTaxByAddress(amount.toFixed(2), TaxType.PLAN, address, additionalLines);
        return res;
    }
);

export const fetchSIMTax = createReachApiThunk(
    "purchase/fetchSIMTax",
    async ({ amount, address, additionalLines }: { amount: number; address: Address; additionalLines: number }) => {
        const res = await ReachCsAPI.getTaxByAddress(amount.toFixed(2), TaxType.SIM, address, additionalLines);
        return res;
    }
);

export const purchaseSlice = createSlice({
    name: "purchase",
    initialState,
    reducers: {
        couponRemoved(state) {
            state.appliedCoupon = undefined;
        },
        planTaxReset(state) {
            state.planTaxRes = undefined;
        },
        addressAdded(state, action: PayloadAction<Address>) {
            const address = action.payload;
            if (!state.customer.addresses || state.customer.addresses.length === 0) {
                state.customer.addresses = [];
            }
            if (address.type === "billing") {
                state.customer.addresses[0] = address;
            } else {
                state.customer.addresses[1] = address;
            }
        },
        imeiAdded(state, action: PayloadAction<string>) {
            state.customer.imei = action.payload;
        },
        purchaseReset: () => initialState,
        linesUpdated(state, action: PayloadAction<number>) {
            state.totalLines = action.payload;
        },
        customersLoaded(state, action: PayloadAction<SearchableCustomer>) {
            state.customer = action.payload;
        },
        setSimInHand(state, action: PayloadAction<boolean>) {
            state.isSimInHand = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkZipCoverage.fulfilled, (state, action) => {
                if (action.payload.isValid) {
                    state.customer.zipcode = action.payload.Zip;
                }
            })
            .addCase(checkIMEI.fulfilled, (state, action) => {
                if (action.payload.isValid) {
                    state.customer.make = action.payload.make;
                    state.customer.model = action.payload.model;
                }
            })
            .addCase(fetchPlanTax.fulfilled, (state, action) => {
                state.planTaxRes = action.payload;
            })
            .addCase(fetchSIMTax.fulfilled, (state, action) => {
                state.simTaxRes = action.payload;
            })
            .addCase(getCoupon.fulfilled, (state, action) => {
                state.appliedCoupon = action.payload;
            })
            .addCase(getCouponEligibility.fulfilled, (state, action) => {
                state.appliedCoupon = action.payload;
            })
            .addCase(createAccount.fulfilled, (state, action) => {
                state.customer = action.payload;
            })
            .addCase(updateAccount.fulfilled, (state, action) => {
                state.customer = action.payload;
            })
            .addCase(reset, () => initialState);
    },
});

export const {
    couponRemoved,
    planTaxReset,
    addressAdded,
    imeiAdded,
    purchaseReset,
    customersLoaded,
    linesUpdated,
    setSimInHand,
} = purchaseSlice.actions;

export const customerSelector = (state: RootState) => state.purchase.customer;

export const totalLinesSelector = (state: RootState) => state.purchase.totalLines;

export const isSimInHandSelector = (state: RootState) => state.purchase.isSimInHand;

export const selectedPlanSelector = createSelector(
    (state: RootState) => state,
    customerSelector,
    (state, customer) => plansSelector.selectById(state, customer.reachPlanId!)
);

export const planLinePriceSelector = createSelector(
    selectedPlanSelector,
    (plan) => ((100 - plan!.discountPctg) * plan!.baseLinePrice) / 100
);

export const additionalLinePriceSelector = createSelector(
    selectedPlanSelector,
    totalLinesSelector,
    isSimInHandSelector,
    (plan, totalLines, isSimInHand) => {
        if (isSimInHand) {
            return 0;
        }
        let prices = 0;
        const additionalLines = totalLines - 1;
        if (plan!.addLineSplitPrice) {
            for (let i = 1; i <= additionalLines; i++) {
                prices = prices + plan!.addLineSplitPrice[`${i}`];
            }
        } else {
            prices = additionalLines * plan!.additionalLinePrice;
        }
        return prices;
    }
);

export const planTaxSelector = (state: RootState) => state.purchase.planTaxRes;
export const simTaxSelector = (state: RootState) => state.purchase.simTaxRes;

export const couponSelector = (state: RootState) => state.purchase.appliedCoupon;

export const promoDiscountSelector = createSelector(
    couponSelector,
    planLinePriceSelector,
    additionalLinePriceSelector,
    (coupon, planLinePrice, additionalLinePrice) => {
        if (!coupon) {
            return 0;
        }

        if (coupon.delayByMonths) {
            return 0;
        }

        if (coupon.type === CouponType.REFERRAL) {
            let refereeDiscount = 0;
            if (coupon.refereeDiscInDollar) {
                refereeDiscount = coupon.refereeDiscInDollar;
            } else if (coupon.refereeDiscount) {
                refereeDiscount = (coupon.refereeDiscount * planLinePrice) / 100;
                refereeDiscount += (coupon.refereeDiscount * additionalLinePrice) / 100;
            }
            if (coupon.maxDiscountInDollar) {
                refereeDiscount = Math.min(refereeDiscount, coupon.maxDiscountInDollar);
            }
            return refereeDiscount;
        } else if (coupon.type === CouponType.PROMO) {
            let promoDiscount = 0;
            if (coupon.discountInDollar) {
                promoDiscount = Math.min(coupon.discountInDollar, planLinePrice + additionalLinePrice);
            }
            if (coupon.planDiscount) {
                promoDiscount += (coupon.planDiscount * planLinePrice) / 100;

                if (coupon.secondaryDiscount) {
                    promoDiscount += (coupon.secondaryDiscount * additionalLinePrice) / 100;
                }

                if (coupon.maxDiscountInDollar) {
                    promoDiscount = Math.min(promoDiscount, coupon.maxDiscountInDollar);
                }
            }
            return promoDiscount;
        } else {
            return 0;
        }
    }
);

export const planTaxAmountSelector = createSelector(
    planLinePriceSelector,
    additionalLinePriceSelector,
    promoDiscountSelector,
    (planLinePrice, additionalLinePrice, promoDiscount) => planLinePrice + additionalLinePrice - promoDiscount
);

export const monthlyBillSelector = createSelector(planTaxAmountSelector, planTaxSelector, (planTaxAmount, planTaxRes) =>
    planTaxRes ? planTaxRes.amount + planTaxRes.totalTax : planTaxAmount
);

export const planEstimatePerLineSelector = createSelector(
    monthlyBillSelector,
    totalLinesSelector,
    (monthlyBill, totalLines) => Math.ceil(monthlyBill / totalLines)
);

export const welcomeKitPriceSelector = createSelector(configSelector, totalLinesSelector, (config, totalLines) => {
    const simCostRes = config.simCostRes!;
    const simPrice = ((100 - simCostRes.discountPctg) * simCostRes.cost) / 100;
    let secondarySimPrice = simPrice;
    if (simCostRes.subsequentCost) {
        secondarySimPrice = ((100 - simCostRes.discountPctg) * simCostRes.subsequentCost) / 100;
    }

    return simPrice + secondarySimPrice * (totalLines - 1);
});

export const totalDueSelector = createSelector(
    simTaxSelector,
    welcomeKitPriceSelector,
    (simTax, welcomeKitPriceSelector) => (simTax ? simTax.amount + simTax.totalTax : welcomeKitPriceSelector)
);
