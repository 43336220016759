import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";
import { TicketRes, TicketPayload } from "../utils/types";

const ticketsAdapter = createEntityAdapter<TicketRes>();

interface AdditionalSliceState {
    query?: string;
    allDepTickets?: [];
    status?: number;
    loading: boolean;
    loadingAdditional: boolean;
    error?: string;
    totalCount?: number;
    currentCount?: number;
}

export const getAllDepTickets = createAsyncThunk(
    "tickets/getAllDepTickets",
    async ({ update, ...payload }: TicketPayload) => {
        const res = await ReachCsAPI.getAllDepTickets(payload);
        return res.tickets;
    }
);

const additionalInitialState: AdditionalSliceState = {
    allDepTickets: [],
    loading: true,
    loadingAdditional: false,
    query: "",
};

export const allDepTicketSlice = createSlice({
    name: "allDepTickets",
    initialState: ticketsAdapter.getInitialState(additionalInitialState),
    reducers: {
        ticketsInserted: ticketsAdapter.setAll,
        ticketsUpdated: ticketsAdapter.upsertMany,
        queryUpdated(state, action: PayloadAction<string>) {
            state.query = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDepTickets.fulfilled, (state, action) => {
                if (action.meta.arg.update) {
                    ticketsAdapter.upsertMany(state, action.payload[0].data);
                } else if (action.payload) {
                    ticketsAdapter.setAll(state, action.payload[0].data);
                }
                state.totalCount = action.payload[0].count;
                state.currentCount = action.payload[0].currentCount;
                state.loading = false;
                state.loadingAdditional = false;
                state.error = undefined;
            })
            .addCase(getAllDepTickets.pending, (state, action) => {
                if (action.meta.arg.update) {
                    state.loadingAdditional = true;
                } else {
                    state.loading = true;
                }
                state.error = undefined;
            })
            .addCase(getAllDepTickets.rejected, (state, action) => {
                state.loading = false;
                state.loadingAdditional = false;
                state.error = "Error";
            });
    },
});

export const { ticketsInserted, ticketsUpdated, queryUpdated } = allDepTicketSlice.actions;

export const ticketsSelector = ticketsAdapter.getSelectors((state: RootState) => state.allDepTickets);

export const ticketSearchSelector = createSelector(
    (state: RootState) => state.allDepTickets,
    (state) => ({
        loading: state.loading,
        loadingAdditional: state.loadingAdditional,
        query: state.query,
        totalCount: state.totalCount,
        currentCount: state.currentCount,
    })
);
