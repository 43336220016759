// Generated by https://quicktype.io

import { DisconnectionReason } from ".";

export enum CustomerStatus {
    INACTIVE = 0,
    ACTIVATION_IN_PROGRESS = 1,
    ACTIVE = 2,
    SUSPENDED = 3,
    DISCONNECTED = 4,
    HOTLINED = 5,
    REJECTED = 6,
    DISABLED = 7,
    ACTIVATION_FAILED = 8,
    PORTING_IN_PROGRESS = 9,
    PORTING_FAILED = 10,
    PORT_CANCELLED = 11,
}

export interface Customer {
    id: string;
    zipcode: string;
    imei?: string;
    firstName: string;
    lastName: string;
    userName: string;
    isMNP: boolean;
    mnpInfo?: MnpInfo;
    isPrimary: boolean;
    primaryNumber: string;
    emailId: string;
    reachNumber: string;
    reachPlanId: string;
    planId: string;
    make: string;
    model: string;
    groupId: string;
    secondaryCustomers: string[];
    addresses: Address[];
    customerNumber: string;
    createdDate: number | string;
    modifiedDate: number | string;
    source: string;
    activationStatus: number;
    activationDate?: number | string;
    appNotifId: string;
    nextBillingDate?: string;
    billGenerationDate: string;
    isThrottled: boolean;
    hubSpotId: number;
    isRecipient: boolean;
    isRoaming: boolean;
    simId?: string;
    lastDisconnectedReason?: DisconnectionReason;
    roamingPackName: string;
    totalTopupInGB: number;
    totalTopupCost: number;
    status: CustomerStatus;
    additionalLines: number;
    throttleEnabledDates: any[];
    throttleDisabledDates: any[];
    roamingEnabledDates: string[];
    roamingDisabledDates: string[];
    referralsCount: number;
    simPaid: boolean;
    skipBilling: boolean;
    billStartDate: string;
    billEndDate: string;
    shipmentInfo: ShipmentInfo;
    billDue: boolean;
    isInvited: boolean;
    extraLines: number;
    flowVersion: FlowVersion;
    lastThrottledPercentage: number;
    noOfSimsOrdered: number;
    isThrottleRemovedSilently: boolean;
    isCDMALessEnabled: boolean;
    isCIDBEnabled: boolean;
    isCardHold: boolean;
    totalLatePayments: number;
    simPurchaseDate: string;
    isILDEnabled: boolean;
    planName: string;
    billPaid: boolean;
    selectedShippingMethod: ShippingMethod;
    lastDisconnectedDate?: string;
    lastHotlinedDate: string;
    discountLevel: string;
    lastReconnectedDate?: string;
    isDraft: boolean;
    isSimInHand: boolean;
    parentId?: string;
    autoTopUpStatus?: AutoTopUpStatus;
    operationsInProcess?: OperationsInProcess[];
    appInfo?: CustomerAppInfo;
    leadSource?: string;
    laterDisconnectionDate?: string;
}

export interface CustomerAppInfo {
    os?: string;
    appVersion?: string;
    device?: string;
    osVersion?: string;
}

export enum OperationsInProcess {
    SUSPEND = "SUSPEND_IN_PROCESS",
    RESTORE = "RESTORE_IN_PROCESS",
    PORT_OUT = "PORT_OUT",
    NEW_TO_PORT = "NEW_TO_PORT",
    PORT_TO_NEW = "PORT_TO_NEW",
    RECONNECT_PORTED_OUT = "RECONNECT_PORTED_OUT",
    RESET = "RESET",
    ACCOUNT_SWAP = "ACCOUNT_SWAP_IN_PROCESS",
    NEW_NUMBER_GENERATION = "NEW_NUMBER_GENERATION",
    IR_TROUBLESHOOT = "IR_TROUBLESHOOT",
}

export enum AutoTopUpStatus {
    ENABLED = 1,
    DISABLED = 2,
    FAILED = 3,
}

export enum FlowVersion {
    INITIAL = 0,
    FLOW_1 = 1,
}

export interface Address {
    name: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    type: string;
    phone: string;
}

export interface MnpInfo {
    acctNum?: string;
    oldNumber?: string;
    operator?: string;
    pin?: string;
    oldPassword?: string;
    oldZip?: string;
}

export interface ShipmentInfo {
    [key: string]: string;
}
export interface ShippingMethod {
    carrierCode: string;
    deliveryDays: number;
    name: string;
    reachCarrierId: string;
    serviceCode: string;
    serviceType: string;
    shippingAmount: number;
}
