import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { version } from "../../package.json";
import { authStateSelector, reset, signOut } from "../features/auth-slice";
import { isReachSSOEnabled } from "../utils/helpers";
import { persistor, useAppDispatch } from "../utils/store";
import { ErrorSnackbarProvider } from "./error-snackbar";
import { SuccessModalProvider } from "./success-modal";
import { useSelector } from "react-redux";
import { Navbar } from "./navbar";
import branding from "../branding/branding.json";

interface Props {
    children: ReactNode;
    title?: string;
}

const Layout = ({ children, title }: Props) => {
    const dispatch = useAppDispatch();
    const { isReachLogin } = useSelector(authStateSelector);
    return (
        <>
            {title && <Helmet title={`${title} | ${branding.common} Operations`} />}
            <Navbar
                onSignOut={async () => {
                    const ssoToken = localStorage.getItem("id_token");
                    if (!isReachSSOEnabled()) {
                        await dispatch(signOut());
                    }
                    dispatch(reset());
                    persistor.purge();
                    localStorage.clear();
                    sessionStorage.clear();
                    if (ssoToken) {
                        if (isReachLogin) {
                            window.location.href = process.env.SSO_REACH_LOGOUT_URL;
                        } else {
                            window.location.href = process.env.SSO_LOGOUT_URL;
                        }
                    } else {
                        window.location.href = "/";
                    }
                }}
            />
            <ErrorSnackbarProvider>
                <SuccessModalProvider>
                    <main role="main" className="flex-shrink-0">
                        {children}
                    </main>
                </SuccessModalProvider>
            </ErrorSnackbarProvider>

            <footer className="footer mt-auto p-3">
                <div className="text-grey1">v{version}</div>
            </footer>
        </>
    );
};

export default Layout;
