import React, { DetailedHTMLProps, SelectHTMLAttributes } from "react";
import classNames from "classnames";

export interface SelectProps extends DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    errorMessage?: string;
    showError?: boolean;
    register?: any;
}

export default function Select(props: SelectProps) {
    const { className, register, showError, errorMessage, ...rest } = props;
    return (
        <div className={className}>
            <select
                className={classNames("custom-select", {
                    "is-invalid": showError,
                })}
                ref={register}
                {...rest}
            />
            {showError && errorMessage && <div className="invalid-feedback font-family-medium">{errorMessage}</div>}
        </div>
    );
}
