import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../utils/store";

const initialState: { message?: string } = {};

export const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        setSnackbarMessage(state, action: PayloadAction<string | undefined>) {
            state.message = action.payload;
        },
    },
});

export const { setSnackbarMessage } = snackbarSlice.actions;

export const snackbarSelector = (state: RootState) => state.snackbar?.message;
