import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { addDays, compareAsc, subMonths } from "date-fns";
import { Data, getClosestSize } from "../utils/data-conversion";
import { getUTCDate } from "../utils/helpers";
import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { RootState } from "../utils/store";

interface Usage {
    bytes: number;
    maxBytes: number;
    topupBytes?: number;
    extraBytes?: number;
    isCurrentBillingDate?: boolean;
    cappedUsage: Data;
    maxUsage: Data;
    extraUsage?: Data;
    topupUsage?: Data;
    isProrated?: boolean;
    billMonth?: string;
}

export interface UserUsage extends Usage {
    userId: string;
    phoneNumber: string;
}

interface GroupUsage extends Usage {
    groupId: string;
    percent: number;
}

interface MonthlyDataUsage {
    startDate: string | number;
    endDate: string | number;
    group?: GroupUsage;
    users: UserUsage[];
}

interface OverallUsage {
    id: string;
    overall: MonthlyDataUsage[];
}

export const getCDRDataUsage = createReachApiThunk("dataUsage/getCDRDataUsage", async (customerId: string) => {
    const res = await ReachCsAPI.getCDRDataUsage(customerId);
    return res;
});

const dataUsageAdapter = createEntityAdapter<OverallUsage>({
    selectId: (model) => model.id,
});

export const dataUsageSlice = createSlice({
    name: "dataUsage",
    initialState: dataUsageAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCDRDataUsage.fulfilled, (state, action) => {
            const res = action.payload;
            const usages: MonthlyDataUsage[] = [];

            const id = action.meta.arg;

            for (const key in res) {
                const usage = res[key];

                let group: GroupUsage | undefined;

                if (usage.group) {
                    const cappedBytes = Math.min(usage.group.bytes, usage.group.maxBytes);
                    const extraBytes = Math.max(usage.group.bytes - usage.group.maxBytes, usage.group.extraBytes);
                    group = {
                        ...usage.group,
                        percent: (usage.group.bytes * 100) / usage.group.maxBytes,
                        cappedUsage: getClosestSize(cappedBytes),
                        maxUsage: getClosestSize(usage.group.maxBytes),
                        extraUsage: getClosestSize(extraBytes),
                        topupUsage: getClosestSize(usage.group.topupBytes ?? 0),
                    };
                }

                const totalUsersUsage = usage.users.map((usage) => usage.bytes).reduce((prev, curr) => prev + curr, 0);

                const users: UserUsage[] = usage.users.map((user) => {
                    let cappedBytes: number;
                    let extraBytes = 0;
                    if (group) {
                        if (totalUsersUsage === 0) {
                            cappedBytes = 0;
                        } else {
                            cappedBytes = (user.bytes * Math.min(group.bytes, group.maxBytes)) / totalUsersUsage;
                        }
                    } else {
                        cappedBytes = Math.min(user.bytes, user.maxBytes);
                        extraBytes = Math.max(user.bytes - user.maxBytes, user.extraBytes);
                    }
                    return {
                        ...user,
                        cappedUsage: getClosestSize(cappedBytes),
                        maxUsage: getClosestSize(user.maxBytes),
                        extraUsage: getClosestSize(extraBytes),
                        topupUsage: getClosestSize(user.topupBytes ?? 0),
                    };
                });

                const monthlyUsage = {
                    startDate: getUTCDate(addDays(subMonths(new Date(key), 1), 1)).toISOString(),
                    endDate: getUTCDate(new Date(key)).toISOString(),
                    users,
                    group,
                };

                usages.push(monthlyUsage);
            }

            usages.sort((a, b) => compareAsc(new Date(a.endDate), new Date(b.endDate)));

            dataUsageAdapter.upsertOne(state, {
                id,
                overall: usages,
            });
        });
    },
});

export const dataUsageSelector = dataUsageAdapter.getSelectors<RootState>((state) => state.dataUsage);
