import React, { ReactNode } from "react";
import Form from "react-bootstrap/Form";
import { FormCheckProps } from "react-bootstrap/esm/FormCheck";
import classNames from "classnames";

export interface RadioProps extends FormCheckProps {
    id: string;
    name: string;
    label: ReactNode;
    inline?: boolean;
    small?: boolean;
    register?: any;
}

export const Radio = (props: RadioProps) => {
    const { label, inline = false, id, name, small = false, register, className, ...rest } = props;
    return (
        <Form.Check
            custom
            type="radio"
            className={classNames(className, {
                "custom-radio-sm": small,
            })}
            ref={register}
            id={id}
            name={name}
            label={label}
            inline={inline}
            {...rest}
        />
    );
};
